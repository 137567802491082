import moment from "moment";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import PriceItemContainer from "../../components/PriceItemContainer";
import ProductCategoryDropdown from "../../components/ProductCategoryDropdown";
import UnitDropdown from "../../components/UnitsPerSKUDropdown";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import SKUUnitDropdown from "../../components/SKUUnitDropdown";
import BaseUnitDropdown from "../../components/BaseUnitDropdown";

function CreateProduct() {
    const {
        handleInputChange,
        fieldChange,
        formData,
        submitForm,
        error,
        setError,
    } = useForm();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        const data = {
            ...formData,
            product_stock: 0,
            status: "ACTIVE",
        };
        submitForm("POST", "/product/cascadeCreate", data, () => {
            history.push("/dashboard/productManagement");
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/productManagement">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createProduct")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div className="">
                            <div
                                className="grid grid-cols-2 gap-9"
                                style={{ maxWidth: "995px" }}
                            >
                                <div className="">
                                    <label className="x-label input-required">
                                        {formatMessage(
                                            "common.productCategory"
                                        )}
                                    </label>
                                    <ProductCategoryDropdown
                                        required
                                        name="product_category_id"
                                        value={formData.product_category_id}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="">
                                    <label className="x-label input-required">
                                        {formatMessage("common.productName")}
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        name="product_name"
                                        onChange={handleInputChange}
                                        className="x-input"
                                    />
                                </div>
                                <div className="">
                                    <label className="x-label input-required">
                                        {formatMessage("common.unitSku")}
                                    </label>
                                    <BaseUnitDropdown
                                        required
                                        value={formData.product_unit_id}
                                        name="product_unit_id"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="">
                                    <label className="x-label">
                                        {formatMessage("common.description")}
                                    </label>
                                    <input
                                        type="text"
                                        name="description"
                                        onChange={handleInputChange}
                                        className="x-input"
                                    />
                                </div>
                            </div>

                            <PriceItemContainer
                                sku_unit_id={formData.product_unit_id}
                                product_prices={formData.product_prices}
                                setItems={fieldChange("product_prices")}
                            />
                        </div>
                        <div className="mt-8">
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/productManagement`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateProduct;
