import React, { useEffect, useState } from 'react'
import searchIcon from '../../img/icon-search.png'
import '../../css/common.css'
import dotsIcon from '../../img/icondots.png'
import reloadIcon from '../../img/icon-reload.png'
import { Link, useHistory } from 'react-router-dom'
import { useFetchData } from '../../hooks/useFetchData'
import { BACKEND_URL } from '../../utils/constant'
import { Pagination } from '../../components/Pagination'
import editIcon from '../../img/icon-edit.png'
import trashCanIcon from '../../img/icon-trash.png'
import Popup from '../../components/Popup'
import axios from 'axios'
import { useFormatMessage } from '../../hooks/useFormatMessage'
import { useForm } from '../../hooks/useForm'
import PasswordInput from '../../components/PasswordInput'
import { ResetPopup } from '../../components/ResetPopup'

function User() {
  const history = useHistory();
  const [popup, setPopup] = useState({
    id: '',
    eID: '',
    show: false
  })
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false)
  const {data, setCurrentPage, setQuery, reload} = useFetchData({
    url: BACKEND_URL + '/user',
    defaultQuery: 'status=ACTIVE'
  })
  const formatMessage = useFormatMessage();

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll('.popup-options.open')
    console.log(activeOptionEl)
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl)
    {
      optionEl.classList.toggle('open')
      setisOptionOpen(optionEl.classList.contains('open'))
    }
  }

  const onSearchChange = e => {
    const text = e.target.value;
    setQuery({
      username: text.trim()
    })
  }

  const onCloseClick = (e) => {
    e.preventDefault();
    setPopup({
      id: '',
      eID: '',
      show: false
    })
  }

  const onConfirmClick = e => {
    e.preventDefault();
    axios.delete(BACKEND_URL + '/user/' + popup.id, { withCredentials: true })
    .then(response => {
      if(response.status === 200)
      {
        setPopup({
          id: '',
          show: false,
          eID: ''
        })
        reload();
      }
    })
    .catch(e => {
      console.log(e.response)
    })
  }

  const onDeleteClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'confirm-popup',
      id: id
    });
  }

  const onResetClick = e => {
    e.preventDefault(); 
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      eID: 'reset-popup',
      id: id
    })
  }

  useEffect(() => {
    document.getElementById('external-popup').classList.toggle('show', popup.show)
  
  }, [popup.show])
  

  useEffect(() => {
    const openedEl = document.querySelector('.popup-options.open');

    const handleDocumentClick = e => {
      if (e.target != openedEl && !openedEl.contains(e.target))
      {
        openedEl.classList.remove('open')
        setisOptionOpen(false)
      }
    }
    if (openedEl)
    {
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick)

      }, 100)

    }
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isOptionOpen])
  


  return (
    <div className='page-layout'>
      <div className='page-header'>
        <h1>{ formatMessage('common.userManagement') }</h1>
        <div className='search-bar'>
          <img src={searchIcon} />
          <input className='x-input' type='search' autoComplete='off' onChange={onSearchChange} />
        </div>
        <Link to='/dashboard/userManagement/create' className='create-button'>{ formatMessage('common.createNew') }</Link>
      </div>

      <div className='table-container'>
        <table className='x-table'>
          <thead>
            <tr>
              <th>{ formatMessage('common.no') }</th>
              <th>{ formatMessage('common.name') }</th>
              <th>{ formatMessage('common.username') }</th>
              <th>{ formatMessage('common.userRole') }</th>
              <th>{ formatMessage('common.phone') }</th>
              <th className='button-col'></th>
            </tr>

          </thead>
          <tbody>
            {
              data.results.map((item, i) => {
                const index = ((data.page - 1) * data.limit) + i + 1;
                return (
                <tr key={i} className='hover-effect'>
                  <td>{index}</td>
                  <td>{item.name}</td>
                  <td>{item.username}</td>
                  <td>{item.role.user_role_name}</td>
                  <td>{item.phone}</td>
                  <td className='button-col'>
                    <img src={dotsIcon} onClick={() => toggleOptions(item.user_id)} />
                    <div className='popup-options'  id={item.user_id}>
                      <Link to={`${currentPath}/${item.user_id}/edit`}><img src={editIcon}/>{ formatMessage('common.edit') }</Link>
                      <a href='' onClick={onResetClick}><img src={reloadIcon}/>{ formatMessage('common.resetPassword') }</a>
                      <a href='' onClick={onDeleteClick}><img src={trashCanIcon}/>{ formatMessage('common.remove') }</a>
                    </div>
                  </td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <div className='pagination-container'>
        <Pagination data={data} setCurrentPage={setCurrentPage}/>
      </div>

      <div className='popup-container'></div>

      <Popup className='popup-container'>
        { popup.eID === 'confirm-popup' && <ConfirmPopup onConfirmClick={onConfirmClick} onCloseClick={onCloseClick}/> }
        { popup.eID === 'reset-popup' && <ResetPopup setPopup={setPopup} popup={popup}/>  }
        

      </Popup>

    </div>
  )
}

const ConfirmPopup = ({onConfirmClick, onCloseClick}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className='popup'>
      <h4 className='text-center'>{ formatMessage('common.messageConfirmation') }</h4> 
      <p>{  formatMessage('common.messageConfirmationDes') }</p>
      <div className='flex justify-between'>
        <button onClick={onConfirmClick} className='black-btn'>{  formatMessage('common.proceed') }</button>
        <button onClick={onCloseClick} className='white-btn'>{  formatMessage('common.cancel2') }</button>
      </div>
    </div>
  )
}



export default User