exports.API_URL = "http://localhost:3000";
// exports.BACKEND_URL = 'https://stagingapi.sinmin.edkamm.com/api/v1'
exports.BACKEND_URL = 'https://api.sinmin.edkamm.com/api/v1'
// exports.BACKEND_URL = "http://localhost:3100/api/v1";

exports.voucherHTML = `

<div className="voucher-container">
    <h4 className="owner">ဦးမျိုးမြင့်အောင် + ဒေါ်မို့မို့ခိုင်</h4>
    <div className="logo-container">
    <img className="logo-1" src={voucherLogo1} />
    <img className="logo-2" src={voucherLogo2} />
    <img className="logo-3" src={voucherLogo3} />
    </div>
    <h5 className="location">ဆိပ်ခွန် ၊ ရွှေဘို</h5>
    <div className="contact">
    <img className="phone" src={voucherPhone} />
    <span>09-2100373, 09-942909968</span>
    <img className="fb" src={voucherFb} />
    <span>https://www.facebook.com/sinminseikkhunlongyi</span>
    </div>
    <div className="name-date">
    <span>အမည် - </span>
    <span>{ saleData && saleData.customer.customer_name} </span>
    <span>နေ့စွဲ - </span>
    <span>{ saleData && moment(saleData.created_date).format('DD.MM.YYYY') }</span>
    </div>
    <table className="voucher-table">
    <tr>
        <th>စဥ်</th>
        <th>အမျိုးအမည်</th>
        <th>ဦးရေ</th>
        <th>နှုန်း</th>
        <th>သင့်ငွေ</th>
    </tr>
                    <tbody>
                        {saleItemArray.map((item) => {
                            return saleItemData && item <= saleItemData.length ? (
                                <tr key={item}>
                                    <td>{item}</td>
                                    <td>{saleItemData[item - 1].product.product_name}</td>
                                    <td>{saleItemData[item - 1].quantity}</td>
                                    <td>{formatCurrency(saleItemData[item - 1].price)}</td>
                                    <td>
                                        {formatCurrency(
                                            saleItemData[item - 1].quantity *
                                                saleItemData[item - 1].price
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                <tr key={item}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            );
                        })}
                        
                    </tbody>
    </table>
    <div className="footer">
    <div className="note">ချမ်းသာမှုပေါင်းပြည့်စုံပါစေ</div>
    <div className="total-calc">
        <div className="item">
        <span>စုစုပေါင်း</span>
        <span>-</span>
        <span>
            {saleData && formatCurrency(saleData.total_amount)}
        </span>
        </div>
        <div className="item">
        <span>လျှော့စျေး</span>
        <span>-</span>
        <span>
            {saleData && formatCurrency(saleData.promotion_amount)}
        </span>
        </div>
        <div className="item">
        <span>စုစုပေါင်း</span>
        <span>-</span>
        <span>
            {saleData && formatCurrency(saleData.total_amount)}
        </span>
        </div>
    </div>
    </div>
    <div className="sign">
    <span>လက်မှတ်</span>
    <span> --------------------------------</span>
    </div>
</div>
`;

exports.voucherCSS = `* {
    box-sizing: border-box;
}
#voucher {
    /* display: none; */ 
    /* max-width: 420px; */
    width: 100%;
    box-shadow: 3px 3px 4px black;
}

.vpage-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    gap: 30px
}

.voucher-container 
{
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;
    padding: 9px 12px 50px 13px;
}

.owner {
    font-size: 12px;
    text-align: center;
}

.logo-container {
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
    align-items: end;
}

.logo-1, .logo-2 {
    width: auto;
    height: 42px;
}

.logo-3 {
    width: auto;
    height: 30px;
    margin-bottom: 3px;
}

.location {
    text-align: center;
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: 8px;
}

.contact 
{
    color: white;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #424243;
    padding: 7px 9px 6px 9px;
}

.contact span {
    color: white;
    display: block;
    line-height: 11.9px;
    font-size: 9px;
    letter-spacing: .5px;
}

.contact img {
}

.contact .phone {
    width: 11px;
    height: auto;
    margin-right: 8px;
}

.contact .fb {
    width: 13px;
    height: 13px;
    margin-left: 21px;
    margin-right: 8px;
}

.name-date 
{
    margin-top: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.name-date span {
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    line-height: 24px;
}

.name-date .label {
    display: flex;
    gap: 8px
}

.voucher-table {
    width: 100%;
    border-spacing: 0;
}

.voucher-table th {
    background-color: #424243;
    color: white;
}

.voucher-table{

    border-top: 1px solid rgba(0, 0, 0, 0.55);
    border-left: 1px solid rgba(0, 0, 0, 0.55);
}

.voucher-table td{
    border-right: 1px solid rgba(0, 0, 0, 0.55);
    border-bottom: 1px solid rgba(0, 0, 0, 0.55);
}

.voucher-table th {
    font-weight: normal;
    line-height: 16px !important;
}

.voucher-table th:nth-child(1) {
    width: 40px;
}
.voucher-table th:nth-child(2) {
    width: 50%;
}

.voucher-table th, .voucher-table td {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 7px;
    height: 26px;
}

.voucher-table td:nth-child(1) {
    text-align: center;
}


.voucher-table td:nth-child(3) {
    text-align: center;
}

.voucher-table td:nth-child(4) {
    text-align: end;
}

.voucher-table td:nth-child(5) {
    text-align: end;
}

.footer
{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.note {
    font-size: 12px;
    white-space: nowrap;
}

.total-calc {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.total-calc .item {
    display: flex;
    font-size: 12px;
    gap: 12px;
}

.total-calc .item span {
    font-size: 12px;
    display: block;
}

.total-calc .item span:nth-child(1) {
    min-width: 50px;
}
.total-calc .item span:nth-child(2) {

}
.total-calc .item span:nth-child(3) {
    min-width: 60px;
    text-align: end;
    margin-right: 9px;
}

.sign {
    margin-top: 30px;
    margin-left: 80px;
}
.sign span {
    font-size: 12px;
}

#voucher-page {
    position: absolute;
    left: 18px;
    bottom: 10px;
    font-size: 12px;
}
`;


