import React, { useEffect, useRef, useState } from "react";
import sin_min_logo from "../img/app_logo.jpeg";
import "../css/login.css";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSwitchLang } from "../hooks/useSwitchLang";
import { useAuth } from "../context/AuthProvider";
import axios from "axios";
import { BACKEND_URL } from "../utils/constant";
import { useForm } from "../hooks/useForm";

function Login() {
    const { switchLang, lang, setUser, checkUser, loading, setLoading } = useAuth();
    const { handleInputChange, formData, error, setError, submitForm } =
        useForm();

    const onChangeLang = (e) => {
        // console.log(e.target.id)
        switchLang(e.target.id);
    };

	useEffect(() => {
		document.getElementById('external-popup').classList.toggle('show', false)  
	  
	  }, [loading])

    const onsubmit = (e) => {
        e.preventDefault();
        const options = {
            method: "POST",
            endpoint: "/auth/login",
        };
        setLoading(true);
        submitForm("POST", "/auth/login", formData, (response) => {
            sessionStorage.setItem('token', response.data.data.token)
            checkUser();
        });
    };

    return (
        <section className="page">
            <div className="login-section">
                <div className="container">
                    <div className="left-side">
                        <img className="logo" src={sin_min_logo} />
                    </div>

                    <div className="divider"></div>

                    <form className="right-side" onSubmit={onsubmit}>
                        <h1>
                            <FormattedMessage id="login.login" />
                        </h1>
                        <div className="input-item">
                            <label
                                className="input-required"
                                htmlFor="username"
                            >
                                <FormattedMessage id="login.username" />
                            </label>
                            <input
                                required
                                id="username"
                                type="text"
                                name="username"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div
                            className="input-item"
                            style={{ marginTop: "20px" }}
                        >
                            <label
                                htmlFor="password"
                                className="input-required"
                            >
                                <FormattedMessage id="login.password" />
                            </label>
                            <input
                                required
                                id="password"
                                type="password"
                                name="password"
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="radio-item">
                            <input
                                id="eng"
                                name="lang"
                                checked={lang === "en"}
                                type="radio"
                                onChange={onChangeLang}
                            />
                            <label htmlFor="eng">
                                <FormattedMessage id="login.eng" />
                            </label>
                            <input
                                id="myan"
                                name="lang"
                                checked={lang === "my"}
                                type="radio"
                                onChange={onChangeLang}
                            />
                            <label htmlFor="myan">
                                <FormattedMessage id="login.myan" />
                            </label>
                        </div>
                        <div className="w-full">
                            <p className="text-red-400 p-0 mt-2">
                                {error && "Incorrect username or password!"}
                            </p>
                        </div>
                        <div className="button-container">
                            <button className="cancel-btn">
                                <FormattedMessage id="login.cancel" />
                            </button>
                            <button type="submit" className="login-btn">
                                <FormattedMessage id="login.login" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Login;
