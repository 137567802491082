import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useFormatMessage } from '../hooks/useFormatMessage'
import { BACKEND_URL } from '../utils/constant';
import editIcon from '../img/icon-edit.png'
import trashCanIcon from '../img/icon-trash.png'
import dotsIcon from '../img/icondots.png'
import Popup from './Popup'
import { Link, useHistory } from 'react-router-dom';
import iconPrint from '../img/icon-print.png'
import { useForm } from '../hooks/useForm';
import { useAuth } from '../context/AuthProvider';
import moment from 'moment';

function MakePaymentPopup({popup, setPopup}) {
  const formatMessage = useFormatMessage();
  const history = useHistory()
  const {setLoading} = useAuth();
  const {formData, setFormData, submitForm, handleInputChange} = useForm({
    sale_id: popup.sale_id,
    payment_receive_date: moment().format('YYYY-MM-DD'),
    payment_receive_amount: ''
  });
  const [error, setError] = useState('')


  const onSubmit = e => {
    e.preventDefault();
    setLoading(true)
    const url = BACKEND_URL + '/paymentReceive/getRemainingAmountBySaleId';
    console.log('sale_id', popup.sale_id)
    axios.post(`${url}`, { sale_id: popup.sale_id }, {
      withCredentials: true
    })
    .then(response => {
      const remaining = response.data.data.remaining
      if (formData.payment_receive_amount > remaining)
      {
        setLoading(false)
        setError(`Amount exceeds the required payment amount(${remaining})`)
      }
      else {

        console.log({formData})
        submitForm('POST', '/paymentReceive', formData, () => {
          history.push('/dashboard/saleVoucher')
      })
      }

    })
    .catch(e => {
      setLoading(false)
        console.log(e.response)
    })



  }
  
  return (

    <Popup className='popup-container'>
        <form onSubmit={onSubmit} className='w-full bg-white pt-12 pr-14 pb-12 pl-14 rounded-md' style={{maxWidth: '600px'}}>
            <h1 className='mb-10' style={{fontSize: '26px'}}>{ formatMessage('common.makePayment') }</h1>

            <div className='mb-7'>
                <label className='x-label input-required'>{ formatMessage('common.amount')}</label>
                <input
                className='x-input'
                type='number'
                required
                name='payment_receive_amount'
                onChange={handleInputChange}
                />
            </div>
            <div className='mb-7'>
                <label className='x-label input-required'>{ formatMessage('common.acceptedDate')}</label>
                <input
                type='date'
                value={formData.payment_receive_date}
                className='x-input'
                required
                name='payment_receive_date'
                onChange={handleInputChange}
                />
            </div>
            <div className='mb-7'>
                <label className='x-label'>{ formatMessage('common.paymentType')}</label>
                <div className='dd-container'> 
                  <select
                  className='x-input dropdown'
                  // name=''
                  // required
                  // className='x-input'
                  >
                    <option value='Cash'>Cash</option>
                    <option value='Bank'>Bank</option>
                  </select>

                </div>
            </div>

            <div className=''>
                <label className='x-label '>{ formatMessage('common.remark')}</label>
                <input
                className='x-input'
                type='text'
    
                name='remark'
                onChange={handleInputChange}
                />
            </div>

            <span className='text-red-500'>{ error }</span>
            <div className='mt-11 flex justify-center gap-5'>
                <div onClick={() => (setPopup({ ...popup, show: false }))} className='white-btn'>{ formatMessage('common.cancel') }</div>
                <button type='submit' className='black-btn'>{ formatMessage('common.makePayment') }</button>
            </div>


        </form>
    </Popup>
  )
}

export default MakePaymentPopup