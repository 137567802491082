import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {useFormatMessage} from '../hooks/useFormatMessage'
import calendarIcon from '../img/icon-calendar.png'
import { formatCurrency } from '../utils/common'
import { BACKEND_URL } from '../utils/constant'
import { Link } from 'react-router-dom'

export default function Dashboard() {
  const formatMessage = useFormatMessage()
  const [stats, setStats] = useState(null)
  const [customers, setCustomers] = useState(null)

  useEffect(() => {
    axios.get(BACKEND_URL + '/stats', { withCredentials: true })
    .then(response => {
      setStats(response.data.data)
    })
    .catch(e => {
      console.log(e.response)
    })  

    axios.get(BACKEND_URL + '/stats/topTenCustomers', { withCredentials: true })
    .then(response => {
      setCustomers(response.data.data)
    })
    .catch(e => {
      console.log(e.response)
    })  
  
  }, [])
  


  return (
    <div className='home-layout'>
      <h1 className='title'>{ formatMessage('common.dashboard') }</h1>
      
      <div className='amount-stats-container'>
        <div className='box'>
          <img src={calendarIcon} className='icon' />
          <h4 className='label'>{ formatMessage('common.dailyWholesale') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.totalAmount.wholesale.daily : 0)} MMK</p>
        </div>
        <div className='box'>
          <img src={calendarIcon} className='icon' />
          <h4 className='label'>{ formatMessage('common.weeklyWholesale') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.totalAmount.wholesale.weekly : 0)} MMK</p>
        </div>
        <div className='box'>
          <img src={calendarIcon} className='icon' />
          <h4 className='label'>{ formatMessage('common.monthlyWholesale') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.totalAmount.wholesale.monthly : 0)} MMK</p>
        </div>
        <div className='box'>
          <Link to={'/dashboard/weeklySaleDues'}>
            <img src={calendarIcon} className='icon' />
            <h4 className='label'>{ formatMessage('common.weeklySaleDues') }</h4>
            <p className='amount'>{ formatCurrency(stats ? stats.dues.weekly : 0)}</p>
          </Link>

        </div>
      </div>

      <h3 className='table-title'>{ formatMessage('common.topCustomerList', 10)}</h3>

      <div className='table-container'>
        <table className='x-table'>
          <thead>
            <tr>
              <th>{ formatMessage('common.no') }</th>
              <th>{ formatMessage('common.name') }</th>
              <th>{ formatMessage('common.customerType') }</th>
              <th>{ formatMessage('common.vehicleName') }</th>
              <th>{ formatMessage('common.phone') }</th>
            </tr>

          </thead>
          <tbody>
            {
              customers && customers.map((item, index) => {
                return (
                <tr key={index} className=''>
                  <td>{index+1}</td>
                  <td>{item.customer.customer_name}</td>
                  <td>{item.customer.customer_type}</td>
                  <td>{item.customer.vehicle.vehicle_name}</td>
                  <td>{item.customer.customer_phone_1}</td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
