import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BACKEND_URL } from '../utils/constant'

function ProductCategoryDropdown({...rest}) {

  const [vehicleType, setvehicleType] = useState([])

  useEffect(() => {
    const url = BACKEND_URL + '/productCategory?status=ACTIVE'
    axios.get(url, { withCredentials: true })
    .then(response => {
        setvehicleType(response.data.data)
    })
    .catch(e => {
        console.log(e)
    })
  }, [])
  

  return (
    <div className='dd-container'>
        <select 
        type='text' 
        value=''
        className='x-input dropdown'
        {...rest}
        >
            <option value=''>Select</option>
            {
                vehicleType.map((item, index) => 
                (
                <option key={index} value={item.product_category_id}>
                    {item.product_category_name}
                </option>
                ))
            }
        </select>

    </div>
  )
}

export default ProductCategoryDropdown