import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency } from "../../utils/common";

function VehicleDetailReport() {
    const formatMessage = useFormatMessage();
    const [data, setdata] = useState({
        // page: 1,
        // limit: 10,
        results: null,
    });

    useEffect(() => {
        let url = BACKEND_URL + "/report/vehicleReport";
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setdata({
                    ...data,
                    results: response.data,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, []);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.vehicleDetailReport")}</h1>
                {/* <form onSubmit={onSearch} className="flex">
                    <input
                        required
                        name="start_date"
                        onChange={onChange}
                        className="x-input mr-3"
                        type="date"
                    />
                    <input
                        required
                        name="end_date"
                        onChange={onChange}
                        className="x-input mr-4"
                        type="date"
                    />
                    <button type="submit" className="header-img-button mr-1">
                        <img src={searchIcon} />
                    </button>
                    <button className="header-img-button">
                        <img src={printIcon} />
                    </button>
                </form> */}
            </div>

            {data.results && data.results.data && (
                <div className="table-container">
                    <table className="x-table">
                        <thead>
                            <tr>
                                <th>{formatMessage("common.no")}</th>
                                <th>{formatMessage("common.vehicleName")}</th>
                                <th>{formatMessage("common.vehicleNo")}</th>
                                <th>{formatMessage("common.vehicleType")}</th>
                                <th>{formatMessage("common.customerCount")}</th>
                                <th>{formatMessage("common.distributedCities")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.results.data.map((item, i) => {
                                return (
                                    <tr key={i} className="hover-effect">
                                        <td>{i + 1}</td>
                                        <td>{item.vehicle_name}</td>
                                        <td>{item.vehicle_no}</td>
                                        <td>{item.vehicle_type}</td>
                                        <td>{item.customer_count}</td>
                                        <td>{item.cities}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default VehicleDetailReport;
