import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function ProductSearchDropdown({
    rowId,
    setItems,
    sale_items,
    value,
    name,
    ...rest
}) {
    const [products, setProducts] = useState({
        origin: [],
        valid: [],
        render: [],
    });

    // to update product list after saleItem product dd selected
    useEffect(() => {
        console.log(sale_items);
        let tempProducts = products.origin;
        tempProducts = tempProducts.filter((item) => {
            let included = true;
            let plength = sale_items.filter(
                (d, i) => i != rowId && d.product_id == item.product_id
            ).length;
            if (plength > 1) {
                return false;
            }

            sale_items.forEach((d, i) => {
                if (
                    i != rowId &&
                    d.product_id == item.product_id &&
                    d.foc_status == false &&
                    !(
                        (sale_items[rowId].foc_status != true &&
                            d.foc_status == true) ||
                        sale_items[rowId].foc_status == true
                    )
                ) {
                    included = false;
                }
            });
            return included;
        });
        setProducts({
            ...products,
            valid: tempProducts,
            render: tempProducts
        });
    }, [sale_items]);

    useEffect(() => {
        const url = BACKEND_URL + "/product?status=ACTIVE";
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                let results = response.data.data;

                let tempProducts = results.filter((item) => {
                    let temp = sale_items.filter(
                        (d, i) =>
                            d.product_id == item.product_id &&
                            d.foc_status == false
                    );
                    let isExist = temp.length > 0;
                    return !isExist;
                });
                setProducts({
                    origin: results,
                    valid: tempProducts,
                    render: tempProducts,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const clickItem = (index) => (e) => {
        const value = e.target.getAttribute("data-id");
        console.log(value);
        const temp = [...sale_items];
        temp[index] = {
            ...temp[index],
            product_id: value,
        };
        setItems(temp);
    };

    const searchDDChange = (e) => {
        console.log("search");
        let temp = [...products.valid];
        let input = e.target.value.trim();
        if (input.length) {
            temp = temp.filter((item) => {
                let temp = input || "";
                temp = temp.trim().toLowerCase();
                const regex = new RegExp(
                    `\\b${temp}\\b|^${temp}|${temp}|${temp}$`
                );
                let found = regex.test(item.product_name.toLowerCase());
                console.log(found);
                return found != false;
            });
        }
        setProducts({
            ...products,
            render: temp,
        });
    };

    const currentProduct = products.origin.filter(
        (d) => d.product_id == value
    )[0];
    console.log(currentProduct);
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <input
                        name={name}
                        value={
                            currentProduct ? currentProduct.product_name : ""
                        }
                        className="x-input cursor-pointer"
                        readOnly
                        placeholder="Select"
                        {...rest}
                    />
                    <ChevronDownIcon
                        className="absolute top-1/2 right-3 -translate-y-1/2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute max-h-96 overflow-y-auto right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item className="cursor-pointer" disabled>
                            <div className="px-2">
                                <input
                                    onChange={searchDDChange}
                                    className="x-input"
                                />
                            </div>
                        </Menu.Item>
                        {products.render.map((item, i) => (
                            <Menu.Item key={i} className="cursor-pointer">
                                {({ active }) => (
                                    <span
                                        data-id={item.product_id}
                                        onClick={clickItem(rowId)}
                                        className={classNames(
                                            active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                        )}
                                    >
                                        {item.product_name}
                                    </span>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
