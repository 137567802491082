import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import plusIcon from "../../img/icon-plus.png";
import "../../css/sale.css";
import axios from "axios";
import { BACKEND_URL } from "../../utils/constant";

function SaleHome() {
  const history = useHistory();
	const [data, setData] = useState(null)
  const formatMessage = useFormatMessage();

	const onSearchClick = e => {
		e.preventDefault();
		const text = document.getElementById('search-customer-input').value;
		const url = BACKEND_URL + '/customer/searchByNameAndShop';
    const payload = {
      customer_name: text,
      shop_name: text
    }
		axios.post(url, payload,{ withCredentials: true })
		.then(response => {
      console.log(response.data.data)
			setData(response.data.data)
		})
		.catch(e => {
			console.log(e)
		})
	}

  return (
    <div className="page-layout">
      <div className="page-header ml-7">
        <h1>{formatMessage("common.sale")}</h1>
      </div>

      <form onSubmit={onSearchClick} className="search-container">
        <div className="search-bar">
          <img src={searchIcon} />
          <input
            className="x-input"
            type="search"
						id="search-customer-input"
          />
        </div>
        <button 
          type="submit"
          className="create-button"
					onClick={onSearchClick}
        >
          {formatMessage("common.search")}
        </button>
        <Link
          className="add-customer"
          to="/dashboard/saleManagement/createCustomer"
        >
          <img src={plusIcon} />
        </Link>
      </form>

      {data && <div className="table-container">
        <table className="x-table">
          <thead>
            <tr>
              <th>{formatMessage("common.no")}</th>
              <th>{formatMessage("common.name")}</th>
              <th>{formatMessage("common.customerType")}</th>
              <th>{formatMessage("common.vehicleName")}</th>
              <th>{formatMessage("common.shopName")}</th>
              <th>{formatMessage("common.city")}</th>
              <th>{formatMessage("common.phone")}</th>
              <th className="button-col" style={{maxWidth: '115px'}}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr key={i} className="">
                  <td>{i+1}</td>
                  <td>{item.customer_name}</td>
                  <td>{item.customer_type}</td>
                  <td>{item.vehicle.vehicle_name}</td>
                  <td>{item.shop_name}</td>
                  <td>{item.city.name}</td>
                  <td>{item.customer_phone_1}</td>
                  <td className="button-col" style={{maxWidth: '115px'}}>
                    <Link to={'/dashboard/saleManagement/' + item.customer_id + '/createSale'} className="table-btn-style-1">Voucher</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>}
    </div>
  );
}

export default SaleHome;
