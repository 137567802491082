import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashIcon from "../../img/icon-trash.png";
import detailsIcon from "../../img/icon-details.png";
import Popup from "../../components/Popup";
import axios from "axios";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import moment from "moment";
import { formatCurrency } from "../../utils/common";

function SaleAccepted() {
    const history = useHistory();
    const [popup, setPopup] = useState({
        id: "",
        show: false,
    });
    const currentPath = history.location.pathname;
    const [isOptionOpen, setisOptionOpen] = useState(false);
    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: BACKEND_URL + "/saleInvoice/search",
        defaultQuery: "status=ACTIVE",
    });
    const formatMessage = useFormatMessage();

    const toggleOptions = (id) => {
        const optionEl = document.getElementById(id);
        const activeOptionEl = document.querySelectorAll(".popup-options.open");
        console.log(activeOptionEl);
        if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
            optionEl.classList.toggle("open");
            setisOptionOpen(optionEl.classList.contains("open"));
        }
    };

    const onSearchChange = (e) => {
        // enter key down
        if (e.keyCode == 13) {
            const text = e.target.value;
            setQuery({
                searchValue: text.trim(),
            });
        }
    };

    const searchClose = (e) => {
        if (e.target.value.trim() === "") {
            setQuery({
                searchValue: "",
            });
        }
    };

    const onCloseClick = (e) => {
        e.preventDefault();
        setPopup({
            id: "",
            show: false,
        });
    };

    const onConfirmClick = (e) => {
        e.preventDefault();
        popup.callback();
    };

    const onDeleteClick = (e) => {
        e.preventDefault();
        const id = e.target.parentNode.id;
        const callback = () => {
            axios
                .delete(BACKEND_URL + "/saleInvoice/" + id, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (response.status === 200) {
                        setPopup({
                            id: "",
                            show: false,
                            callback: () => {},
                        });
                        reload();
                    }
                })
                .catch((e) => {
                    console.log(e.response);
                });
        };
        setPopup({
            show: true,
            id: id,
            callback,
        });
    };

    const onWriteOffClick = (e) => {
        e.preventDefault();
        const id = e.target.parentNode.id;
        const callback = () => {
            axios
                .put(
                    BACKEND_URL + "/saleInvoice/" + id,
                    { status: "INACTIVE" },
                    { withCredentials: true }
                )
                .then((response) => {
                    if (response.status === 200) {
                        setPopup({
                            id: "",
                            show: false,
                            callback: () => {},
                        });
                        reload();
                    }
                })
                .catch((e) => {
                    console.log(e.response);
                });
        };
        setPopup({
            show: true,
            id: id,
            callback,
        });
    };

    useEffect(() => {
        document
            .getElementById("external-popup")
            .classList.toggle("show", popup.show);
    }, [popup.show]);

    useEffect(() => {
        const openedEl = document.querySelector(".popup-options.open");

        const handleDocumentClick = (e) => {
            if (e.target != openedEl && !openedEl.contains(e.target)) {
                openedEl.classList.remove("open");
                setisOptionOpen(false);
            }
        };
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener("click", handleDocumentClick);
            }, 100);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOptionOpen]);

    const goToPayment = (sale_id, sale_invoice_id) => (e) => {
        e.preventDefault();
        console.log({ sale_id });
        const url = BACKEND_URL + "/paymentReceive/getRemainingAmountBySaleId";
        axios
            .post(
                `${url}`,
                { sale_id },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                const remaining = response.data.data.remaining;
                console.log({ remaining });
                if (remaining > 0) {
                    history.push(
                        `/dashboard/saleVoucher/${sale_invoice_id}/edit`
                    );
                }
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>{formatMessage("common.saleAccepted")}</h1>
                <div className="search-bar">
                    <img src={searchIcon} />
                    <input
                        className="x-input"
                        type="search"
                        onKeyDown={onSearchChange}
                        onInput={searchClose}
                    />
                </div>
            </div>

            <div className="table-container">
                <table className="x-table">
                    <thead>
                        <tr>
                            <th>{formatMessage("common.no")}</th>
                            <th>{formatMessage("common.voucherNo")}</th>
                            <th>{formatMessage("common.customerName")}</th>
                            <th>{formatMessage("common.amount")}</th>
                            <th>{formatMessage("common.remainAmount")}</th>
                            <th>{formatMessage("common.acceptedDate")}</th>
                            <th
                                className="button-col flex-shrink-1"
                                style={{ maxWidth: "180px" }}
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.results.map((item, i) => {
                            const index = (data.page - 1) * data.limit + i + 1;
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{index}</td>
                                    <td>{item.sale_invoice}</td>
                                    <td>{item.customer.customer_name}</td>
                                    <td>{formatCurrency(item.total_amount)}</td>
                                    <td>{formatCurrency(item.remaining)}</td>
                                    <td>
                                        {item.due_date &&
                                            moment(item.due_date).format(
                                                "DD.MM.YYYY"
                                            )}
                                    </td>
                                    <td
                                        className="button-col"
                                        style={{
                                            maxWidth: "180px",
                                            justifyContent: "space-between",
                                            gap: "10px",
                                        }}
                                    >
                                        <Link
                                            className="details"
                                            to={`/dashboard/saleVoucher/${item.sale_invoice_id}/details`}
                                        >
                                            {formatMessage("common.details")}
                                        </Link>
                                        <img
                                            src={dotsIcon}
                                            onClick={() =>
                                                toggleOptions(
                                                    item.sale_invoice_id
                                                )
                                            }
                                        />
                                        <div
                                            className="popup-options"
                                            id={item.sale_invoice_id}
                                        >
                                            <Link
                                                to={``}
                                                onClick={goToPayment(
                                                    item.sale_id,
                                                    item.sale_invoice_id
                                                )}
                                            >
                                                <img src={detailsIcon} />
                                                {formatMessage(
                                                    "common.makePayment"
                                                )}
                                            </Link>
                                            <a href="" onClick={onDeleteClick}>
                                                <img src={trashIcon} />
                                                {formatMessage("common.remove")}
                                            </a>
                                            <Link
                                                to={`#`}
                                                onClick={onWriteOffClick}
                                            >
                                                <img src={editIcon} />
                                                {formatMessage(
                                                    "common.writeOff"
                                                )}
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="pagination-container">
                <Pagination data={data} setCurrentPage={setCurrentPage} />
            </div>

            <div className="popup-container"></div>

            <Popup className="popup-container">
                {popup.show && (
                    <div className="popup">
                        <h4 className="text-center">
                            {formatMessage("common.messageConfirmation")}
                        </h4>
                        <p>{formatMessage("common.messageConfirmationDes")}</p>
                        <div className="flex justify-between">
                            <button
                                onClick={onConfirmClick}
                                className="black-btn"
                            >
                                {formatMessage("common.proceed")}
                            </button>
                            <button
                                onClick={onCloseClick}
                                className="white-btn"
                            >
                                {formatMessage("common.cancel2")}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    );
}

export default SaleAccepted;
