import PasswordInput from "./PasswordInput";

const { useState } = require("react");
const { useForm } = require("../hooks/useForm");
const { useFormatMessage } = require("../hooks/useFormatMessage");


export const ResetPopup = ({setPopup, popup}) => {
    const formatMessage = useFormatMessage();
    const [eyes, seteyes] = useState([false, false])
  
    const {handleInputChange, formData, submitForm, setError, error} = useForm();
  
    const onSubmit = e => {
      e.preventDefault();

      const data = {
        password: formData.password
      }
      submitForm('PUT', '/user/' + popup.id, data, () => {
        setPopup({
          id: '',
          eID: '',
          show: false
        })
      });
  
  
    }
  
    const onCloseClick = e => {
      e.preventDefault();
      setPopup({
        id: '',
        eID: '',
        show: false
      })
    }
  
    return (
      <form onSubmit={onSubmit} className='popup w-full' 
      style={{
        maxWidth: '600px ',
        padding: '50px 60px 68px 60px '  
      }}>
        <h4 className='text-start'>‌{ formatMessage('common.resetPassword') }</h4>
        <div className='w-full mt-10'>
          <label  className='input-required'>{ formatMessage('common.newPassword') }</label>
          <PasswordInput 
          name='password'
          required
          onChange={handleInputChange}
          />
        </div>
        
        <div className=' mt-14'>
          { error ? <span className='w-full block text-lg text-red-500 text-center mb-4'>{error}</span> : ''}
          <div className='flex justify-center gap-5'>
            <button onClick={onCloseClick} className='white-btn'>{ formatMessage('common.cancel') }</button>
            <button type='submit' className='black-btn'>{ formatMessage('common.update') }</button>
          </div>
        </div>
      </form>
    )
}

