import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency } from "../../utils/common";

function ReceivableReportByDateAndCity() {
    const history = useHistory();
    const params = useParams();
    const formatMessage = useFormatMessage();
    const [data, setdata] = useState({
        start_date: "",
        end_date: "",
        city: "",
        // page: 1,
        // limit: 10,
        results: null,
    });

    const [city, setcity] = useState([]);

    const onSearch = (e) => {
        e.preventDefault();
        let query = [];
        query.push(`start_date=${data.start_date}`);
        query.push(`end_date=${data.end_date}`);
        query.push(`city_id=${data.city_id}`);

        query = query.join("&");
        if (!query) {
            return;
        }
        let url = BACKEND_URL + "/report/receivableByDateAndCity";
        axios
            .get(`${url}?${query}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setdata({
                    ...data,
                    results: response.data,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setdata({
            ...data,
            [name]: value,
        });
    };

    useEffect(() => {
        axios
            .get(
                BACKEND_URL + "/address?name=city",
                { withCredentials: true }
            )
            .then((response) => {
                setcity(response.data.data)
            });
    }, []);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex flex-col gap-6 text-3xl">
                <h1>{formatMessage("common.receivableReportByDate")}</h1>
                <form onSubmit={onSearch} className="flex">
                    <div className="dd-container mr-6" >
                        <select
                        style={{minWidth: '240px', padding: '5px 18px 5px 18px'}}
                            type="text"
                            name="city_id"
                            required
                            onChange={onChange}
                            className="x-input dropdown"
                        >
                            <option value="">Select</option>
                            {city.map((item, index) => (
                                <option key={index} value={item.city_id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <input
                        required
                        name="start_date"
                        onChange={onChange}
                        className="x-input mr-3"
                        type="date"
                    />
                    <input
                        required
                        name="end_date"
                        onChange={onChange}
                        className="x-input mr-4"
                        type="date"
                    />
                    <button type="submit" className="header-img-button mr-1">
                        <img src={searchIcon} />
                    </button>
                    <button className="header-img-button">
                        <img src={printIcon} />
                    </button>
                </form>
            </div>

            {data.results && data.results.data && (
                <div className="table-container">
                    <table className="x-table">
                        <thead>
                            <tr>
                                <th>{formatMessage("common.no")}</th>
                                <th>{formatMessage("common.customerName")}</th>
                                <th>{formatMessage("common.voucherNo")}</th>
                                <th>{formatMessage("common.invoiceDate")}</th>
                                <th>{formatMessage("common.acceptedDate")}</th>
                                <th className="text-end">
                                    {formatMessage("common.salesAmount")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.results.data.map((item, i) => {
                                return (
                                    <tr key={i} className="hover-effect">
                                        <td>{i + 1}</td>
                                        <td>{item.customer_name}</td>
                                        <td>{item.sale_invoice}</td>
                                        <td>{item.invoice_date}</td>
                                        <td>{item.due_date}</td>
                                        <td className="text-end">
                                            {formatCurrency(item.amount)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="flex mt-5 justify-end gap-8 pr-12">
                        <span>{formatMessage("common.total")} </span>-
                        <span>{formatCurrency(data.results.total)}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReceivableReportByDateAndCity;
