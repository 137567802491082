import React, { useEffect, useState } from 'react'
import searchIcon from '../../img/icon-search.png'
import '../../css/common.css'
import dotsIcon from '../../img/icondots.png'
import { Link, useHistory } from 'react-router-dom'
import { useFetchData } from '../../hooks/useFetchData'
import { BACKEND_URL } from '../../utils/constant'
import { Pagination } from '../../components/Pagination'
import editIcon from '../../img/icon-edit.png'
import trashIcon from '../../img/icon-trash.png'
import detailsIcon from '../../img/icon-details.png'
import Popup from '../../components/Popup'
import axios from 'axios'
import { useFormatMessage } from '../../hooks/useFormatMessage'
import moment from 'moment'
import { formatCurrency } from '../../utils/common'

function SaleTransaction() {
  const history = useHistory();
  const [popup, setPopup] = useState({
    id: '',
    show: false
  })
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false)
  const {data, setCurrentPage, setQuery, reload} = useFetchData({
    url: BACKEND_URL + '/paymentReceive',
    // defaultQuery: 'status=ACTIVE'
    sort: "-payment_receive_date"
  })
  const formatMessage = useFormatMessage();

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll('.popup-options.open')
    console.log(activeOptionEl)
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl)
    {
      optionEl.classList.toggle('open')
      setisOptionOpen(optionEl.classList.contains('open'))
    }
  }

  const onSearchChange = e => {
    const text = e.target.value;
    setQuery({
      sale_invoice: text.trim()
    })
  }
  useEffect(() => {
    document.getElementById('external-popup').classList.toggle('show', popup.show)
  
  }, [popup.show])
  

  useEffect(() => {
    const openedEl = document.querySelector('.popup-options.open');

    const handleDocumentClick = e => {
      if (e.target != openedEl && !openedEl.contains(e.target))
      {
        openedEl.classList.remove('open')
        setisOptionOpen(false)
      }
    }
    if (openedEl)
    {
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick)

      }, 100)

    }
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isOptionOpen])
  


  return (
    <div className='page-layout'>
      <div className='page-header'>
        <h1>{ formatMessage('nav.saleTransaction') }</h1>
        <div className='search-bar'>
          <img src={searchIcon} />
          <input className='x-input' type='search' onChange={onSearchChange} />
        </div>
      </div>

      <div className='table-container'>
        <table className='x-table'>
          <thead>
            <tr>
              <th>{ formatMessage('common.no') }</th>
              <th>{ formatMessage('common.voucherNo') }</th>
              <th>{ formatMessage('common.customerName') }</th>
              <th>{ formatMessage('common.amount') }</th>
              <th>{ formatMessage('common.date') }</th>
              <th className='button-col'></th>
            </tr>

          </thead>
          <tbody>
            {
              data.results.map((item, i) => {
                const index = ((data.page - 1) * data.limit) + i + 1;
                return (
                <tr key={i} className='hover-effect'>
                  <td>{index}</td>
                  <td>{item.saleInvoiceBySaleId.sale_invoice}</td>
                  <td>{item.saleInvoiceBySaleId.customer.customer_name}</td>
                  <td>{ formatCurrency(item.payment_receive_amount) }</td>
                  <td>{moment(item.payment_receive_date).format('DD.MM.YYYY')}</td>
                  <td className='button-col'>
                    {/* <img src={dotsIcon} 
                    onClick={() => toggleOptions(item.sale_invoice_id)} 
                    />
                    <div className='popup-options' id={item.payment_receive_id}>
                        <Link to={`${currentPath}/${item.sale_invoice_id}/view`}><img src={detailsIcon}/>{ formatMessage('common.details') }</Link>
                    </div> */}
                  </td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <div className='pagination-container'>
        <Pagination data={data} setCurrentPage={setCurrentPage}/>
      </div>

      <div className='popup-container'></div>

    </div>
  )
}

export default SaleTransaction