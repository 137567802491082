import React from "react";
import "../css/voucher.css";
import voucherLogo1 from "../img/voucher_logo_1.png";
import voucherLogo2 from "../img/voucher_logo_2.png";
import voucherLogo3 from "../img/voucher_logo_3.png";
import voucherPhone from "../img/voucher_phone.png";
import voucherFb from "../img/voucher_fb.png";
import moment from "moment";
import { formatCurrency } from "../utils/common";

export default function SaleVoucher({ formData, saleItemData }) {
    const saleItemArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    console.log("voucher data - ", formData, saleItemData);
    return (
        <div id="voucher">
            <div className="voucher-container">
                <h4 className="owner">
                    ဦးမျိုးမြင့်အောင် + ဒေါ်မို့မို့ခိုင်
                </h4>
                <div className="logo-container">
                    <img className="logo-1" src={voucherLogo1} />
                    <img className="logo-2" src={voucherLogo2} />
                    <img className="logo-3" src={voucherLogo3} />
                </div>
                <h5 className="location">ဆိပ်ခွန် ၊ ရွှေဘို</h5>
                <div className="contact">
                    <div style={{ display: 'flex'}}>
                        <img className="phone" src={voucherPhone} />
                        <span>09-2100373, 09-942909968</span>
                    </div>
                    <div style={{ display: 'flex'}}>
                        <img className="fb" src={voucherFb} />
                        <span>
                            https://www.facebook.com/sinminseikkhunlongyi
                        </span>
                    </div>
                </div>
                <div className="name-date">
                    <div className="label">
                        <span>အမည် - </span>
                        <span>
                            {formData && formData.customer.customer_name}{" "}
                        </span>
                    </div>
                    <div className="label">
                        <span>ဘောင်ချာ - </span>
                        <span>{formData && formData.sale_invoice}</span>
                    </div>
                    <div className="label">
                        <span>နေ့စွဲ - </span>
                        <span>
                            {formData &&
                                moment(formData.created_date).format(
                                    "DD.MM.YYYY"
                                )}
                        </span>
                    </div>
                </div>
                <table className="voucher-table">
                    <thead>
                        <tr>
                            <th>စဥ်</th>
                            <th>အမျိုးအမည်</th>
                            <th>ဦးရေ</th>
                            <th>နှုန်း</th>
                            <th>သင့်ငွေ</th>
                        </tr>
                    </thead>
                    <tbody id="voucher-table-body"></tbody>
                </table>
                <div className="footer">
                    <div className="note">ချမ်းသာမှုပေါင်းပြည့်စုံပါစေ</div>
                    <div className="total-calc">
                        <div className="item">
                            <span>စုစုပေါင်း</span>
                            <span>-</span>
                            <span>
                                {formData &&
                                    formatCurrency(formData.total_amount)}
                            </span>
                        </div>
                        <div className="item">
                            <span>လျှော့စျေး</span>
                            <span>-</span>
                            <span>
                                {formData &&
                                    formatCurrency(formData.promotion_amount)}
                            </span>
                        </div>
                        <div className="item">
                            <span>စုစုပေါင်း</span>
                            <span>-</span>
                            <span>
                                {formData &&
                                    formatCurrency(formData.total_amount)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="sign">
                    <span>လက်မှတ်</span>
                    <span> --------------------------------</span>
                </div>
                <span id="voucher-page">1</span>
            </div>
        </div>
    );
}
