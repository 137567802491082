import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider';
import { useForm } from '../../hooks/useForm'
import { useFormatMessage } from '../../hooks/useFormatMessage';
import backArrowIcon from '../../img/back-arrow.png'
import '../../css/common.css'
import VehicleDropdown from '../../components/VehicleDropdown';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/constant';

function CreateCustomer() {
  const {handleInputChange, formData, submitForm, setFormData,setError, error} = useForm();
  const [address, setAddress] = useState({
    country: [],
    division: [],
    city: [],
    township: []
  })
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const {user, setLoading} = useAuth();
  const onSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    const data = {
        ...formData,
        status: 'ACTIVE'
    }
    submitForm('POST', '/customer', data, () => {
        history.push('/dashboard/customer')
    });

  }

  useEffect(() => {
    axios.get(BACKEND_URL + '/address?name=division&id=' +  formData.country_id, { withCredentials: true })
    .then(response => {
        setAddress(old => ({
            ...old, 
            division: response.data.data,
        }))
        setFormData(old => ({
            ...old, 
            division_id: '',
            city_id: '',
            township_id: ''
        }))
    })  
  
  }, [formData.country_id])
  
  useEffect(() => {
    axios.get(BACKEND_URL + '/address?name=city&id=' +  formData.division_id, { withCredentials: true })
    .then(response => {
        setAddress(old => ({
            ...old, 
            city: response.data.data,
        }))
        setFormData(old => ({
            ...old, 
            city_id: '',
            township_id: ''
        }))
    })  
  
  }, [formData.division_id])

  useEffect(() => {
    axios.get(BACKEND_URL + '/address?name=township&id=' +  formData.city_id, { withCredentials: true })
    .then(response => {
        setAddress(old => ({
            ...old, 
            township: response.data.data,
        }))

        setFormData(old => ({
            ...old, 
            township_id: ''
        }))
    })  
  
  }, [formData.city_id])
  

  return (
    <div className='sub-page-layout-1'>
        <Link className='back-to-page' to='/dashboard/customer'>
            <img src={backArrowIcon} />
            { formatMessage('common.back') }
        </Link>
        <div className='container'>
            <h1 className='sub-page-title'>{ formatMessage('common.createCustomer') }</h1>
            <div className='form-container'>
                <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                    <div className='pt-5'>
                        <h2 className='sub-title mb-8'>{ formatMessage('common.customerDetails') }</h2>

                        <div className='grid grid-cols-2 gap-9' style={{maxWidth: '995px'}}>
                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.customerType') }</label>
                                <div className='dd-container'>
                                    <select 
                                    type='text' 
                                    required
                                    name='customer_type'
                                    onChange={handleInputChange}
                                    value={formData.customer_type ? formData.customer_type : ''}
                                    className='x-input dropdown'
                                    >
                                        <option value=''>Select</option>
                                        <option value='wholesale'>Wholesale</option>
                                        <option value='retail'>Retail</option>
                                    </select>

                                </div>
                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.name') }</label>
                                <input type='text' 
                                required
                                name='customer_name' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.phone1') }</label>
                                <input type='text' 
                                required
                                name='customer_phone_1' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>

                            <div className='grow'>
                                <label className='x-label'>{ formatMessage('common.phone2') }</label>
                                <input type='text' 
                                name='customer_phone_2' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>
                            <div className='grow'>
                                <label className='x-label'>{ formatMessage('common.phone3') }</label>
                                <input type='text' 
                                name='customer_phone_3' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>

                            <div className='grow'>
                                <label className='x-label'>{ formatMessage('common.phone4') }</label>
                                <input type='text' 
                                name='customer_phone_4' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>

                            <div className='grow'>
                                <label className='x-label'>{ formatMessage('common.shopName') }</label>
                                <input type='text' 
                                // required
                                name='shop_name' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>

                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.vehicleName') }</label>
                                <VehicleDropdown 
                                required
                                name='vehicle_id' 
                                value={formData.vehicle_id}
                                onChange={handleInputChange}
                                />

                            </div>




                        </div>

                        <div className='form-divider'></div>

                        <h2 className='sub-title mb-8'>{ formatMessage('common.otherInfo') }</h2>

                        <div className='grid grid-cols-2 gap-9' style={{maxWidth: '995px'}}>
                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.country') }</label>
                                <div className='dd-container'>
                                    <select 
                                    type='text' 
                                    required
                                    name='country_id'
                                    onChange={handleInputChange}
                                    value={formData.country_id ? formData.country_id : ''}
                                    className='x-input dropdown'
                                    >
                                        <option value=''>Select</option>
                                        <option value='150'>Myanmar</option>
                                    </select>

                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.division') }</label>
                                <div className='dd-container'>
                                    <select 
                                    type='text' 
                                    required
                                    name='division_id'
                                    onChange={handleInputChange}
                                    value={formData.division_id ? formData.division_id : ''}
                                    className='x-input dropdown'
                                    >
                                        <option value=''>Select</option>
                                        {address.division.map((item, index) => (
                                            <option key={index} value={item.division_id}>{item.name}</option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.city') }</label>
                                <div className='dd-container'>
                                    <select 
                                    type='text' 
                                    name='city_id'
                                    required
                                    onChange={handleInputChange}
                                    value={formData.city_id ? formData.city_id : ''}
                                    className='x-input dropdown'
                                    >
                                        <option value=''>Select</option>
                                        {address.city.map((item, index) => (
                                            <option key={index} value={item.city_id}>{item.name}</option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                           
                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.township') }</label>
                                <div className='dd-container'>
                                    <select 
                                    required
                                    type='text' 
                                    name='township_id'
                                    onChange={handleInputChange}
                                    value={formData.township_id ? formData.township_id : ''}
                                    className='x-input dropdown'
                                    >
                                        <option value=''>Select</option>
                                        {address.township.map((item, index) => (
                                            <option key={index} value={item.township_id}>{item.name}</option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                            <div className='grow'>
                                <label className='x-label input-required'>{ formatMessage('common.address') }</label>
                                <input type='text' 
                                required
                                name='address' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>

                            <div className='grow'>
                                <label className='x-label'>{ formatMessage('common.description') }</label>
                                <input type='text' 
                                name='description' 
                                onChange={handleInputChange}
                                className='x-input'/>

                            </div>

                        </div>

                    </div>
                    <div className='mt-8'> 
                        { error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                        <div className='flex justify-end gap-5 mt-5'>
                            <Link to={`/dashboard/customer`} className='white-btn'>{ formatMessage('common.cancel') }</Link>
                            <button className='black-btn'>{ formatMessage('common.create') }</button>
                        </div>

                    </div>
                </form>

                <div className='side-card-container'>
                    <div className='side-card'>
                        <div className='side-card-list'>
                            <span className='label'>Created by</span>
                            <span className='text'>{user.name}</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Created on</span>
                            <span className='text'>{moment().format('DD.MM.YYYY')}</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated by</span>
                            <span className='text'>-</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated on</span>
                            <span className='text'>-</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    
    </div>

  )
}

export default CreateCustomer