import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency, getFullMonth, getSelectableYears } from "../../utils/common";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DistributionReportByYear() {
    const formatMessage = useFormatMessage();
    const [data, setdata] = useState({
        year: "",
        // page: 1,
        // limit: 10,
        results: null,
    });

    const onSearch = (e) => {
        e.preventDefault();
        let query = [];
        if (!data.year)
        {
            return ;
        }
        query.push(`year=${data.year}`);

        query = query.join("&");
        console.log(query)
        let url = BACKEND_URL + "/report/distributionReportByYear";
        axios
            .get(`${url}?${query}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setdata({
                    ...data,
                    results: response.data,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    const onChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setdata({
            ...data,
            [name]: value,
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.distributionReportByYear")}</h1>
                <form onSubmit={onSearch} className="flex">
                    <div className="dd-container w-48 mr-3">
                        <select
                            required
                            name="year"
                            defaultValue=""
                            className="x-input dropdown"
                            onChange={onChange}
                            style={{padding: '5px 18px'}}
                        > 
                            <option value={""}></option>
                            {getSelectableYears().map((e) => (
                                <option key={e} value={e}>
                                    {e}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button type="submit" className="header-img-button mr-1">
                        <img src={searchIcon} />
                    </button>
                    <button className="header-img-button">
                        <img src={printIcon} />
                    </button>
                </form>
            </div>

            {data.results && data.results.data && (
                <div className="table-container">
                    <table className="x-table  customer-month-report">
                        <thead>
                            <tr>
                                <th>{formatMessage("common.no")}</th>
                                <th>{formatMessage("common.city")}</th>
                                <th className="text-end">Jan</th>
                                <th className="text-end">Feb</th>
                                <th className="text-end">Mar</th>
                                <th className="text-end">Apr</th>
                                <th className="text-end">May</th>
                                <th className="text-end">Jun</th>
                                <th className="text-end">Jul</th>
                                <th className="text-end">Aug</th>
                                <th className="text-end">Sept</th>
                                <th className="text-end">Oct</th>
                                <th className="text-end">Nov</th>
                                <th className="text-end">Dec</th>
                                <th className="text-end">
                                    {formatMessage("common.total")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.results.data.map((item, i) => {
                                return (
                                    <tr key={i} className="hover-effect">
                                        <td>{i + 1}</td>
                                        <td>{item.name}</td>
                                        {
                                            item.records.map((m, i) => (
                                                <td key={i} className="text-end">{formatCurrency(m.amount)}</td>
                                            ))
                                        }
                                        <td className="text-end">
                                            {formatCurrency(item.total)}
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr className="hover-effect">
                                <td></td>
                                <td>Grand Total</td>
                                {data.results.monthTotal.map((amount, i) => (
                                    <td className="text-end" key={i}>{formatCurrency(amount)}</td>
                                ))}
                                <td className="text-end">{ formatCurrency(data.results.monthTotal.reduce((a,b) => a+b, 0))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default DistributionReportByYear;
