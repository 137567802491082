import React, { useEffect, useLayoutEffect, useState } from "react";
import plusIcon from "../img/icon-plus.png";
import "../css/common.css";
import dotsIcon from "../img/icondots.png";
import { useFormatMessage } from "../hooks/useFormatMessage";
import trashCanIcon from "../img/icon-trash.png";
import UnitDropdown from "./UnitsPerSKUDropdown";
import ProductDropdown from "./ProductDropdown";
import ProductUnitDropdown from "./ProductUnitDropdown";
import axios from "axios";
import { BACKEND_URL } from "../utils/constant";
import { formatCurrency } from "../utils/common";
import ProductSearchDropdown from "./ProductSearchDropdown";

function SaleItemTable({ sale_items, setItems, sale_type }) {
    const formatMessage = useFormatMessage();

    useLayoutEffect(() => {
        setItems([
            {
                sort: 1,
                product_id: "",
                unit_id: "",
                quantity: "",
                price: "",
                amount: "",
                foc_status: false,
            },
        ]);
    }, []);

    const onAddNewRow = (e) => {
        const temp = [...sale_items];
        temp.push({
            sort: temp[temp.length - 1].sort + 1,
            product_id: "",
            unit_id: "",
            quantity: "",
            price: "",
            amount: "",
            foc_status: false,
        });
        setItems(temp);
    };

    return (
        <div className="item-container">
            <div className="flex justify-between items-center mr-12">
                <h4>{formatMessage("common.voucherDetails")}</h4>
                <img
                    src={plusIcon}
                    className="icon-button-style-1 cursor-pointer"
                    onClick={onAddNewRow}
                />
            </div>

            <table className="x-table mt-6">
                <thead>
                    <tr>
                        <td
                            className="flex-shrink"
                            style={{ maxWidth: "28px" }}
                        >
                            {formatMessage("common.no")}
                        </td>
                        <td
                            className="input-required"
                            style={{ maxWidth: "200px" }}
                        >
                            {formatMessage("common.product")}
                        </td>
                        <td
                            className="input-required"
                            style={{ maxWidth: "140px" }}
                        >
                            {formatMessage("common.unit")}
                        </td>
                        <td
                            className="input-required"
                            style={{ maxWidth: "98px" }}
                        >
                            {formatMessage("common.quantity")}
                        </td>
                        <td
                            className="input-required"
                            style={{ maxWidth: "120px" }}
                        >
                            {formatMessage("common.price")}
                        </td>
                        <td
                            className="input-required"
                            style={{ maxWidth: "150px" }}
                        >
                            {formatMessage("common.amount")}
                        </td>
                        <td
                            className="text-center"
                            style={{ maxWidth: "88px" }}
                        >
                            {formatMessage("common.free")}
                        </td>
                        <td
                            className="button-col"
                            style={{ maxWidth: "52px" }}
                        ></td>
                    </tr>
                </thead>
                <tbody>
                    {sale_items &&
                        sale_items.map((item, index) => {
                            return (
                                <SaleItemRow
                                    item={item}
                                    rowId={index}
                                    key={item.sort}
                                    sale_items={sale_items}
                                    setItems={setItems}
                                    sale_type={sale_type}
                                />
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

const SaleItemRow = ({ item, sale_items, setItems, rowId, sale_type }) => {
    const [unit, setunit] = useState([]);
    const formatMessage = useFormatMessage();
    const [isOptionOpen, setisOptionOpen] = useState(false);

    useEffect(() => {
        const openedEl = document.querySelector(".popup-options.open");

        const handleDocumentClick = (e) => {
            // e.preventDefault();
            if (e.target != openedEl && !openedEl.contains(e.target)) {
                openedEl.classList.remove("open");
                setisOptionOpen(false);
            }
        };
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener("click", handleDocumentClick);
            }, 100);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOptionOpen]);

    const handleInputChange = (index) => {
        return (e) => {
            const { name, value } = e.target;
            const temp = [...sale_items];
            temp[index] = {
                ...temp[index],
                [name]: value,
            };
            setItems(temp);
        };
    };

    const changeValue = (obj) => {
        const temp = [...sale_items];
        temp[rowId] = {
            ...temp[rowId],
            ...obj,
        };
        setItems(temp);
    };

    const toggleOptions = (id) => {
        const optionEl = document.querySelector(
            `.popup-options[data-id="${id}"]`
        );
        const activeOptionEl = document.querySelectorAll(".popup-options.open");
        if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
            optionEl.classList.toggle("open");
            setisOptionOpen((old) => !old);
        }
    };

    const removeItem = (index) => {
        return (e) => {
            e.preventDefault();
            const openEl = document.querySelector(".popup-options.open");
            openEl.classList.remove("open");
            const temp = [...sale_items];
            temp.splice(index, 1);
            setItems(temp);
        };
    };

    const onCheckBoxClick = (rowId) => (e) => {
        console.log(e);
        if (e.target.name == "foc_status") {
            let focProduct = sale_items.filter(
                (d, i) =>
                    i != rowId && d.product_id == sale_items[rowId].product_id
            );
            console.log("focProduct", focProduct.length);
            if (focProduct.length > 0) {
                e.preventDefault();
                return;
            } else {
                changeValue({
                    foc_status: e.target.checked,
                });
            }
        }
    };

    const onCheckBoxChange = (e) => {};

    useEffect(() => {
        if (item.product_id && item.unit_id && item.quantity) {
            changeValue({
                amount: item.price * item.quantity,
            });
        }
    }, [item.product_id, item.quantity]);

    useEffect(() => {
        changeValue({
            price: "",
            unit_id: "",
            amount: "",
            foc_status: false,
        });
        if (item.product_id) {
            const url =
                BACKEND_URL +
                "/productPrice?included=true&product_id=" +
                item.product_id;
            axios
                .get(url, { withCredentials: true })
                .then((response) => {
                    setunit(response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            setunit([]);
        }
    }, [item.product_id]);

    useEffect(() => {
        if (unit && unit.length && item.unit_id) {
            changeValue({
                amount: item.price * item.quantity,
            });
        }
    }, [item.price]);

    useEffect(() => {
        if (unit && unit.length && item.unit_id) {
            const currentUnit = unit.filter(
                (d) => d.product_unit_id === item.unit_id
            )[0];
            let price =
                sale_type === "wholesale"
                    ? currentUnit.wholesale_price
                    : currentUnit.retail_price;
            const payload = { price };
            if (item.quantity) {
                payload.amount = price * item.quantity;
            }
            changeValue(payload);
        }
    }, [item.unit_id]);

    return (
        <tr className="" style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <td style={{ maxWidth: "28px" }}>{rowId + 1}</td>
            <td style={{ maxWidth: "200px" }}>
                {/* <ProductDropdown
                    required
                    rowId={rowId}
                    setItems={setItems}
                    sale_items={sale_items}
                    value={item.product_id}
                    className="small-padding"
                    name="product_id"
                    onChange={handleInputChange(rowId)}
                /> */}
                <ProductSearchDropdown
                    required
                    rowId={rowId}
                    setItems={setItems}
                    sale_items={sale_items}
                    value={item.product_id}
                    name="product_id"

                />
            </td>
            <td style={{ maxWidth: "140px" }}>
                <ProductUnitDropdown
                    unit={unit}
                    required
                    name="unit_id"
                    className="small-padding"
                    value={item.unit_id}
                    style={{ paddingLeft: "20px" }}
                    onChange={handleInputChange(rowId)}
                />
            </td>
            <td style={{ maxWidth: "98px" }}>
                <input
                    type="number"
                    required
                    name="quantity"
                    value={item.quantity}
                    style={{ padding: "12px" }}
                    onChange={handleInputChange(rowId)}
                    className="x-input small-padding none-appearance"
                />
            </td>
            <td style={{ maxWidth: "120px" }}>
                <input
                    type="text"
                    required
                    name="price"
                    style={{ padding: "12px" }}
                    value={(item.price * 1).toFixed(0)}
                    // disabled
                    onChange={handleInputChange(rowId)}
                    className="x-input small-padding"
                />
            </td>
            <td style={{ maxWidth: "150px" }}>
                <input
                    type="text"
                    required
                    disabled
                    name="amount"
                    value={formatCurrency(item.amount)}
                    onChange={handleInputChange(rowId)}
                    className="x-input small-padding"
                />
            </td>
            <td className="text-center" style={{ maxWidth: "88px" }}>
                <input
                    // key={Math.random()}
                    type="checkbox"
                    name="foc_status"
                    // defaultValue={item.foc_status}
                    checked={item.foc_status}
                    onChange={onCheckBoxChange}
                    onClick={onCheckBoxClick(rowId)}
                    className="x-checkbox"
                />
            </td>
            <td className="button-col" style={{ maxWidth: "52px" }}>
                <img src={dotsIcon} onClick={() => toggleOptions(rowId)} />
                <div className="popup-options" data-id={rowId}>
                    <a href="" onClick={removeItem(rowId)}>
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                    </a>
                </div>
            </td>
        </tr>
    );
};

export default SaleItemTable;
