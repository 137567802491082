import moment from 'moment';
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider';
import { useForm } from '../../hooks/useForm'
import { useFormatMessage } from '../../hooks/useFormatMessage';
import backArrowIcon from '../../img/back-arrow.png'
import '../../css/common.css'
import RoleDropdown from '../../components/RoleDropdown';

function CreateUser() {
  const {handleInputChange, formData, submitForm, setError, error} = useForm();
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const {user, setLoading} = useAuth();
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const data = {
        ...formData,
        status: 'ACTIVE',
    }
    submitForm('POST', '/user', data, () => {
        history.push('/dashboard/userManagement')
    });

  }

  return (
    <div className='sub-page-layout-1'>
        <Link className='back-to-page' to='/dashboard/userManagement'>
            <img src={backArrowIcon} />
            { formatMessage('common.back') }
        </Link>
        <div className='container'>
            <h1 className='sub-page-title'>{ formatMessage('common.createUser') }</h1>
            <div className='form-container'>
                <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                    <div className='grid grid-cols-2 gap-9' style={{maxWidth: '995px'}}>

                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.userRole') }</label>
                            <RoleDropdown 
                            value={formData.user_role_id}
                            onChange={handleInputChange} 
                            required
                            name='user_role_id' 
                            />

                        </div>
                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.name') }</label>
                            <input type='text' 
                            required
                            name='name' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.username') }</label>
                            <input type='text' 
                            required
                            name='username' 
                            autoComplete='false'
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.password') }</label>
                            <input type='password' 
                            required
                            autoComplete='false'
                            name='password' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.phone') }</label>
                            <input type='text' 
                            required
                            name='phone' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label'>{ formatMessage('common.description') }</label>
                            <input type='text' 
                            name='description' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>

                    </div>
                    <div>
                        { error ? <span className='w-full block text-lg text-red-500 text-right'>{error}</span> : ''}
                        <div className='flex justify-end gap-5 mt-5'>
                            <Link to={`/dashboard/userManagement`} className='white-btn'>{ formatMessage('common.cancel') }</Link>
                            <button className='black-btn'>{ formatMessage('common.create') }</button>
                        </div>

                    </div>
                </form>

                <div className='side-card-container'>
                    <div className='side-card'>
                        <div className='side-card-list'>
                            <span className='label'>Created by</span>
                            <span className='text'>{user.name}</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Created on</span>
                            <span className='text'>{moment().format('DD.MM.YYYY')}</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated by</span>
                            <span className='text'>-</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated on</span>
                            <span className='text'>-</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    
    </div>

  )
}

export default CreateUser