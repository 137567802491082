import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import UnitDropdown from "../../components/UnitsPerSKUDropdown";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import BaseUnitDropdown from "../../components/BaseUnitDropdown";
import ConvertedUnitDropdown from "../../components/ConvertedUnitDropdown";

function EditUnitConversion() {
    const { handleInputChange, formData, setFormData, submitForm } = useForm();
    const history = useHistory();
    const params = useParams();
    const { setLoading } = useAuth();
    const formatMessage = useFormatMessage();
    const [xData, setxData] = useState({});

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        submitForm("PUT", "/uoc/" + params.id, data, () => {
            history.push("/dashboard/unitConversion");
        });
    };

    useEffect(() => {
        const url = BACKEND_URL + "/uoc?status=ACTIVE&uoc_id=" + params.id;
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                const temp = response.data.data[0];
                setxData(temp);
                setFormData({
                    base_unit_id: temp.base_unit_id,
                    base_qty: temp.base_qty,
                    converted_unit_id: temp.converted_unit_id,
                    converted_qty: temp.converted_qty,
                    description: temp.description,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, []);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/unitConversion">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.uoc")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.baseUnit")}
                                </label>
                                <BaseUnitDropdown
                                    onChange={handleInputChange}
                                    required
                                    value={
                                        formData.base_unit_id
                                            ? formData.base_unit_id
                                            : ""
                                    }
                                    name="base_unit_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.baseQty")}
                                </label>
                                <input
                                    type="number"
                                    required
                                    name="base_qty"
                                    onChange={handleInputChange}
                                    value={
                                        formData.base_qty
                                            ? formData.base_qty
                                            : ""
                                    }
                                    className="x-input"
                                />
                            </div>

                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.convertedUnit")}
                                </label>
                                <ConvertedUnitDropdown
                                    name="converted_unit_id"
                                    required
                                    value={
                                        formData.converted_unit_id
                                            ? formData.converted_unit_id
                                            : ""
                                    }
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.convertedQty")}
                                </label>
                                <input
                                    type="number"
                                    required
                                    name="converted_qty"
                                    disabled
                                    onChange={handleInputChange}
                                    value={
                                        formData.converted_qty
                                            ? formData.converted_qty
                                            : ""
                                    }
                                    className="x-input"
                                />
                            </div>

                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.description")}
                                </label>
                                <input
                                    type="text"
                                    name="description"
                                    onChange={handleInputChange}
                                    value={
                                        formData.description
                                            ? formData.description
                                            : ""
                                    }
                                    className="x-input"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end gap-5">
                            <Link
                                to={`/dashboard/unitConversion`}
                                className="white-btn"
                            >
                                {formatMessage("common.cancel")}
                            </Link>
                            <button className="black-btn">
                                {formatMessage("common.finish")}
                            </button>
                        </div>
                    </form>

                    <div className="side-card-container">
                        <div className="side-card">
                            <div className="side-card-list">
                                <span className="label">Created by</span>
                                <span className="text">
                                    {xData.created_by_user
                                        ? xData.created_by_user.name
                                        : ""}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Created on</span>
                                <span className="text">
                                    {xData
                                        ? moment(xData.created_date).format(
                                              "DD.MM.YYYY"
                                          )
                                        : ""}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated by</span>
                                <span className="text">
                                    {xData.updated_by
                                        ? xData.updated_by_user.name
                                        : "-"}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated on</span>
                                <span className="text">
                                    {xData.updated_date
                                        ? moment(xData.updated_date).format(
                                              "DD.MM.YYYY"
                                          )
                                        : "-"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditUnitConversion;
