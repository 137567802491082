import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PriceItemContainer from "../../components/PriceItemContainer";
import ProductCategoryDropdown from "../../components/ProductCategoryDropdown";
import UnitDropdown from "../../components/UnitsPerSKUDropdown";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import BaseUnitDropdown from "../../components/BaseUnitDropdown";

function EditProduct() {
    const { setLoading } = useAuth();
    const {
        handleInputChange,
        formData,
        setFormData,
        submitForm,
        fieldChange,
        error,
    } = useForm();
    const history = useHistory();
    const params = useParams();
    const formatMessage = useFormatMessage();
    const [xData, setxData] = useState({});

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        console.log(data);
        submitForm(
            "POST",
            "/product/" + params.id + "/cascadeUpdate",
            data,
            () => {
                history.push("/dashboard/productManagement");
            }
        );
    };

    useEffect(() => {
        const url =
            BACKEND_URL +
            "/product?status=ACTIVE&included=true&product_id=" +
            params.id;
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                const temp = response.data.data[0];
                setxData(temp);
                temp.productPrice.forEach((item) => {
                    delete item.product_id;
                });
                setFormData({
                    product_category_id: temp.product_category_id,
                    product_name: temp.product_name,
                    product_unit_id: temp.product_unit_id,
                    description: temp.description,
                    product_prices: temp.productPrice,
                    product_stock: 0,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, []);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/productManagement">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.product")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div className="">
                            <div
                                className="grid grid-cols-2 gap-9"
                                style={{ maxWidth: "995px" }}
                            >
                                <div className="">
                                    <label className="x-label input-required">
                                        {formatMessage(
                                            "common.productCategory"
                                        )}
                                    </label>
                                    <ProductCategoryDropdown
                                        required
                                        name="product_category_id"
                                        value={formData.product_category_id}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="">
                                    <label className="x-label input-required">
                                        {formatMessage("common.productName")}
                                    </label>
                                    <input
                                        type="text"
                                        required
                                        name="product_name"
                                        onChange={handleInputChange}
                                        value={
                                            formData.product_name
                                                ? formData.product_name
                                                : ""
                                        }
                                        className="x-input"
                                    />
                                </div>
                                <div className="">
                                    <label className="x-label input-required">
                                        {formatMessage("common.unitSku")}
                                    </label>
                                    <BaseUnitDropdown
                                        required
                                        value={formData.product_unit_id}
                                        name="product_unit_id"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="">
                                    <label className="x-label">
                                        {formatMessage("common.description")}
                                    </label>
                                    <input
                                        type="text"
                                        name="description"
                                        onChange={handleInputChange}
                                        value={
                                            formData.description
                                                ? formData.description
                                                : ""
                                        }
                                        className="x-input"
                                    />
                                </div>
                            </div>

                            <PriceItemContainer
                                sku_unit_id={formData.product_unit_id}
                                product_prices={formData.product_prices}
                                setItems={fieldChange("product_prices")}
                            />
                        </div>
                        <div className="mt-8">
                            {error ? (
                                <span className="w-full block mb-3 text-lg text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/productManagement`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.finish")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditProduct;
