import React, { useEffect, useLayoutEffect, useState } from "react";
import plusIcon from "../img/icon-plus.png";
import "../css/common.css";
import dotsIcon from "../img/icondots.png";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { Link } from "react-router-dom";
import trashCanIcon from "../img/icon-trash.png";
import UnitsPerSKUDropdown from "./UnitsPerSKUDropdown";

function PriceItemContainer({ product_prices, setItems, sku_unit_id }) {
    const formatMessage = useFormatMessage();
    const [isOptionOpen, setisOptionOpen] = useState(false);

    useLayoutEffect(() => {
        setItems([
            {
                product_unit_id: "",
                wholesale_price: "",
                retail_price: "",
            },
        ]);
    }, []);

    const handleInputChange = (index) => {
        return (e) => {
            const { name, value } = e.target;
            const temp = [...product_prices];
            temp[index] = {
                ...temp[index],
                [name]: value,
            };
            setItems(temp);
        };
    };

    const onAddNewRow = (e) => {
        const temp = [...product_prices];
        temp.push({
            product_unit_id: "",
            wholesale_price: "",
            retail_price: "",
        });
        setItems(temp);
    };

    const toggleOptions = (id) => {
        const optionEl = document.querySelector(
            `.popup-options[data-id="${id}"]`
        );
        const activeOptionEl = document.querySelectorAll(".popup-options.open");
        console.log(activeOptionEl, optionEl);
        if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
            optionEl.classList.toggle("open");
            setisOptionOpen((old) => !old);
        }
    };

    useEffect(() => {
        const openedEl = document.querySelector(".popup-options.open");

        const handleDocumentClick = (e) => {
            // e.preventDefault();
            if (e.target != openedEl && !openedEl.contains(e.target)) {
                openedEl.classList.remove("open");
                setisOptionOpen(false);
            }
        };
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener("click", handleDocumentClick);
            }, 100);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOptionOpen]);

    const removeItem = (index) => {
        return (e) => {
            e.preventDefault();
            const openEl = document.querySelector(".popup-options.open");
            openEl.classList.remove("open");
            const temp = [...product_prices];
            temp.splice(index, 1);
            setItems(temp);
        };
    };

    return (
        <div className="item-container">
            <div className="flex justify-between items-center mr-12">
                <h4>{formatMessage("common.priceDetail")}</h4>
                <img
                    src={plusIcon}
                    className="icon-button-style-1 cursor-pointer"
                    onClick={onAddNewRow}
                />
            </div>

            <table className="x-table mt-6">
                <thead>
                    <tr>
                        <td className="flex-shrink">
                            {formatMessage("common.no")}
                        </td>
                        <td className="input-required">
                            {formatMessage("common.saleUnit")}
                        </td>
                        <td className="input-required">
                            {formatMessage("common.retailPrice")}
                        </td>
                        <td className="input-required">
                            {formatMessage("common.wholesalePrice")}
                        </td>
                        <td className="button-col"></td>
                    </tr>
                </thead>
                <tbody>
                    {product_prices &&
                        product_prices.map((item, index) => {
                            return (
                                <tr className="" key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <UnitsPerSKUDropdown
                                            required
                                            sku_unit_id={sku_unit_id}
                                            quantity="1"
                                            value={item.product_unit_id}
                                            name="product_unit_id"
                                            onChange={handleInputChange(index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            required
                                            name="retail_price"
                                            value={item.retail_price}
                                            onChange={handleInputChange(index)}
                                            className="x-input"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            required
                                            name="wholesale_price"
                                            value={item.wholesale_price}
                                            onChange={handleInputChange(index)}
                                            className="x-input"
                                        />
                                    </td>
                                    <td className="button-col">
                                        <img
                                            src={dotsIcon}
                                            onClick={() => toggleOptions(index)}
                                        />
                                        <div
                                            className="popup-options"
                                            data-id={index}
                                        >
                                            <a
                                                href=""
                                                onClick={removeItem(index)}
                                            >
                                                <img src={trashCanIcon} />
                                                {formatMessage("common.remove")}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

export default PriceItemContainer;
