import React, { useEffect, useState } from 'react'
import '../../css/common.css'
import dotsIcon from '../../img/icondots.png'
import { Link, useHistory } from 'react-router-dom'
import { useFetchData } from '../../hooks/useFetchData'
import { BACKEND_URL } from '../../utils/constant'
import backArrowIcon from '../../img/back-arrow.png'
import { Pagination } from '../../components/Pagination'
import { useFormatMessage } from '../../hooks/useFormatMessage'
import moment from 'moment'
import { formatCurrency } from '../../utils/common'

function WeeklySaleDues() {
  const history = useHistory();
  const [popup, setPopup] = useState({
    id: '',
    show: false
  })
//   const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false)
  const {data, setCurrentPage, setQuery, reload} = useFetchData({
    url: BACKEND_URL + '/stats/weeklysaleDues',
    // defaultQuery: 'status=ACTIVE'
  })
  const formatMessage = useFormatMessage();

  return (
    <div className='page-layout relative'>
        <Link className='back-to-page' to='/dashboard/home'>
            <img src={backArrowIcon} />
            { formatMessage('common.back') }
        </Link>
    
      <div className='page-header mt-16'>
        <h1>{ formatMessage('common.weeklySaleDues') }</h1>
        {/* <div className='search-bar'>
          <img src={searchIcon} />
          <input className='x-input' type='search' onChange={onSearchChange} />
        </div> */}
      </div>

      <div className='table-container'>
        <table className='x-table'>
          <thead>
            <tr>
              <th>{ formatMessage('common.no') }</th>
              <th>{ formatMessage('common.voucherNo') }</th>
              <th>{ formatMessage('common.customerName') }</th>
              <th>{ formatMessage('common.amount') }</th>
              <th>{ formatMessage('common.acceptedDate') }</th>
              {/* <th className='button-col'></th> */}
            </tr>

          </thead>
          <tbody>
            {
              data.results.map((item, i) => {
                const index = ((data.page - 1) * data.limit) + i + 1;
                return (
                <tr key={i} className=''>
                  <td>{index}</td>
                  <td>{item.sale_invoice}</td>
                  <td>{item.customer.customer_name}</td>
                  <td>{ formatCurrency(item.total_amount) }</td>
                  <td>{item.due_date && moment(item.due_date).format('DD.MM.YYYY')}</td>
                  {/* <td className='button-col'>
                    <img src={dotsIcon} onClick={() => toggleOptions(item.sale_invoice_id)} />
                    <div className='popup-options' id={item.sale_invoice_id}>
                      <Link to={``} onClick={goToPayment(item.sale_id, item.sale_invoice_id)}><img src={detailsIcon}/>{ formatMessage('common.makePayment') }</Link>
                      <a href='' onClick={onDeleteClick}><img src={trashIcon}/>{ formatMessage('common.remove') }</a>
                      <Link to={`#`} onClick={onWriteOffClick}><img src={editIcon}/>{ formatMessage('common.writeOff') }</Link>
                    </div>
                  </td> */}
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>


      <div className='pagination-container'>
        <Pagination data={data} setCurrentPage={setCurrentPage}/>
      </div>

    </div>
  )
}

export default WeeklySaleDues