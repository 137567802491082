import React, { useEffect, useState } from 'react'
import searchIcon from '../../img/icon-search.png'
import '../../css/common.css'
import dotsIcon from '../../img/icondots.png'
import { Link, useHistory } from 'react-router-dom'
import { useFetchData } from '../../hooks/useFetchData'
import { BACKEND_URL } from '../../utils/constant'
import { Pagination } from '../../components/Pagination'
import editIcon from '../../img/icon-edit.png'
import trashCanIcon from '../../img/icon-trash.png'
import Popup from '../../components/Popup'
import axios from 'axios'
import { useFormatMessage } from '../../hooks/useFormatMessage'

function UnitConversion() {
  const history = useHistory();
  const [popup, setPopup] = useState({
    id: '',
    show: false
  })
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false)
  const {data, setCurrentPage, setQuery, reload} = useFetchData({
    url: BACKEND_URL + '/uoc',
    defaultQuery: 'status=ACTIVE'
  })
  const formatMessage = useFormatMessage();

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll('.popup-options.open')
    console.log(activeOptionEl)
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl)
    {
      optionEl.classList.toggle('open')
      setisOptionOpen(optionEl.classList.contains('open'))
    }
  }

  const onSearchChange = e => {
    const text = e.target.value;
    setQuery({
      unit_name: text.trim()
    })
  }

  const onCloseClick = (e) => {
    e.preventDefault();
    setPopup({
      id: '',
      show: false
    })
  }

  const onConfirmClick = e => {
    e.preventDefault();
    axios.delete(BACKEND_URL + '/uoc/' + popup.id, { withCredentials: true })
    .then(response => {
      if(response.status === 200)
      {
        setPopup({
          id: '',
          show: false
        })
        reload();
      }
    })
    .catch(e => {
      console.log(e.response)
    })
  }

  const onDeleteClick = e => {
    e.preventDefault();
    const id = e.target.parentNode.id ? e.target.parentNode.id : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      id: id
    });
  }

  useEffect(() => {
    document.getElementById('external-popup').classList.toggle('show', popup.show)
  
  }, [popup.show])
  

  useEffect(() => {
    const openedEl = document.querySelector('.popup-options.open');

    const handleDocumentClick = e => {
      if (e.target != openedEl && !openedEl.contains(e.target))
      {
        openedEl.classList.remove('open')
        setisOptionOpen(false)
      }
    }
    if (openedEl)
    {
      setTimeout(() => {
        document.addEventListener('click', handleDocumentClick)

      }, 100)

    }
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isOptionOpen])
  


  return (
    <div className='page-layout'>
      <div className='page-header'>
        <h1>{ formatMessage('common.uoc') }</h1>
        <div className='search-bar'>
          <img src={searchIcon} />
          <input className='x-input' type='search' onChange={onSearchChange} />
        </div>
        <Link to='/dashboard/unitConversion/create' className='create-button'>{ formatMessage('common.createNew') }</Link>
      </div>

      <div className='table-container'>
        <table className='x-table'>
          <thead>
            <tr>
              <th>{ formatMessage('common.no') }</th>
              <th>{ formatMessage('common.baseUnit') }</th>
              <th>{ formatMessage('common.baseQty') }</th>
              <th>{ formatMessage('common.convertedUnit') }</th>
              <th>{ formatMessage('common.convertedQty') }</th>
              <th className='button-col'></th>
            </tr>

          </thead>
          <tbody>
            {
              data.results.map((item, i) => {
                const index = ((data.page - 1) * data.limit) + i + 1;
                return (
                <tr key={i} className='hover-effect'>
                  <td>{index}</td>
                  <td>{item.baseUnit.unit_name}</td>
                  <td>{item.base_qty}</td>
                  <td>{item.convertedUnit.unit_name}</td>
                  <td>{item.converted_qty}</td>
                  <td className='button-col'>
                    <img src={dotsIcon} onClick={() => toggleOptions(item.uoc_id)} />
                    <div className='popup-options' id={item.uoc_id}>
                      <Link to={`${currentPath}/${item.uoc_id}/edit`}><img src={editIcon}/>{ formatMessage('common.edit') }</Link>
                      <a href='' onClick={onDeleteClick}><img src={trashCanIcon}/>{ formatMessage('common.remove') }</a>
                    </div>
                  </td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <div className='pagination-container'>
        <Pagination data={data} setCurrentPage={setCurrentPage}/>
      </div>

      <div className='popup-container'></div>

      <Popup className='popup-container'>
        { popup.show &&
        <div className='popup'>
          <h4 className='text-center'>{  formatMessage('common.messageConfirmation') }</h4>
          <p>{  formatMessage('common.messageConfirmationDes') }</p>
          <div className='flex justify-between'>
            <button onClick={onConfirmClick} className='black-btn'>{  formatMessage('common.proceed') }</button>
            <button onClick={onCloseClick} className='white-btn'>{  formatMessage('common.cancel2') }</button>
          </div>
        </div>}

      </Popup>

    </div>
  )
}

export default UnitConversion