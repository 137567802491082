import React from 'react'
import Popup from './Popup'
import '../css/common.css'
import '../css/loading.css'



function LoadingPopup() {
  return (
    <Popup className="popup-container show">
      {/* <div className='Popup max-w-sm w-full px-12 py-7 bg-white rounded text-lg'>
        
      </div> */}
      <div className='loading-container' style={{position: 'absolute'}}>
        <span className='loading-ball-1'></span>
        <span className='loading-ball-2'></span>
      </div>
    </Popup>
  )
}

export default LoadingPopup