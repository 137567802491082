import React, { useEffect, useLayoutEffect, useState } from "react";
import plusIcon from "../img/icon-plus.png";
import "../css/common.css";
import dotsIcon from "../img/icondots.png";
import { useFormatMessage } from "../hooks/useFormatMessage";
import trashCanIcon from "../img/icon-trash.png";
import UnitDropdown from "./UnitsPerSKUDropdown";
import ProductUnitDropdown from "./ProductUnitDropdown";
import axios from "axios";
import { BACKEND_URL } from "../utils/constant";
import { formatCurrency } from "../utils/common";

function SaleItemDetailsTable({ sale_items, setItems, sale_type }) {
    const formatMessage = useFormatMessage();

    useLayoutEffect(() => {
        setItems([
            {
                product_id: "",
                unit_id: "",
                quantity: "",
                price: "",
                amount: "",
                foc_status: false,
            },
        ]);
    }, []);

    const onAddNewRow = (e) => {
        const temp = [...sale_items];
        temp.push({
            product_id: "",
            unit_id: "",
            quantity: "",
            price: "",
            amount: "",
            foc_status: false,
        });
        setItems(temp);
    };

    console.log(sale_items);

    return (
        <div className="item-container">
            <div className="flex justify-between items-center mr-12">
                <h4>{formatMessage("common.voucherDetails")}</h4>
            </div>

            <table className="x-table mt-6">
                <thead>
                    <tr>
                        <td className="flex-shrink">
                            {formatMessage("common.no")}
                        </td>
                        <td style={{ maxWidth: "177px" }}>
                            {formatMessage("common.product")}
                        </td>
                        <td style={{ maxWidth: "108px" }}>
                            {formatMessage("common.unit")}
                        </td>
                        <td style={{ maxWidth: "108px" }}>
                            {formatMessage("common.quantity")}
                        </td>
                        <td style={{ maxWidth: "150px" }}>
                            {formatMessage("common.price")}
                        </td>
                        <td style={{ maxWidth: "108px" }}>
                            {formatMessage("common.amount")}
                        </td>
                        <td
                            className="text-center"
                            style={{ maxWidth: "108px" }}
                        >
                            {formatMessage("common.free")}
                        </td>
                        <td className="button-col"></td>
                    </tr>
                </thead>
                <tbody>
                    {sale_items &&
                        sale_items.map((item, index) => {
                            return (
                                <SaleItemRow
                                    item={item}
                                    rowId={index}
                                    key={index}
                                    sale_items={sale_items}
                                    setItems={setItems}
                                    sale_type={sale_type}
                                />
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

const SaleItemRow = ({ item, sale_items, setItems, rowId, sale_type }) => {
    const [unit, setunit] = useState([]);
    const formatMessage = useFormatMessage();
    const [isOptionOpen, setisOptionOpen] = useState(false);

    useEffect(() => {
        const openedEl = document.querySelector(".popup-options.open");

        const handleDocumentClick = (e) => {
            // e.preventDefault();
            if (e.target != openedEl && !openedEl.contains(e.target)) {
                openedEl.classList.remove("open");
                setisOptionOpen(false);
            }
        };
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener("click", handleDocumentClick);
            }, 100);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOptionOpen]);

    const handleInputChange = (index) => {
        return (e) => {
            const { name, value } = e.target;
            const temp = [...sale_items];
            temp[index] = {
                ...temp[index],
                [name]: value,
            };
            setItems(temp);
        };
    };

    const changeValue = (obj) => {
        const temp = [...sale_items];
        temp[rowId] = {
            ...temp[rowId],
            ...obj,
        };
        setItems(temp);
    };

    const toggleOptions = (id) => {
        const optionEl = document.querySelector(
            `.popup-options[data-id="${id}"]`
        );
        const activeOptionEl = document.querySelectorAll(".popup-options.open");
        console.log(activeOptionEl, optionEl);
        if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
            optionEl.classList.toggle("open");
            setisOptionOpen((old) => !old);
        }
    };

    const removeItem = (index) => {
        return (e) => {
            e.preventDefault();
            const openEl = document.querySelector(".popup-options.open");
            openEl.classList.remove("open");
            const temp = [...sale_items];
            temp.splice(index, 1);
            setItems(temp);
        };
    };

    const onCheckBoxChange = (e) => {
        changeValue({
            foc_status: e.target.checked,
        });
    };

    useEffect(() => {
        if (item.product_id && item.unit_id && item.quantity) {
            changeValue({
                amount: item.price * item.quantity,
            });
        }
    }, [item.product_id, item.quantity]);

    useEffect(() => {
        changeValue({
            price: "",
            unit_id: "",
            amount: "",
        });
        if (item.product_id) {
            const url =
                BACKEND_URL +
                "/productPrice?included=true&product_id=" +
                item.product_id;
            axios
                .get(url, { withCredentials: true })
                .then((response) => {
                    setunit(response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            setunit([]);
        }
    }, [item.product_id]);

    useEffect(() => {
        if (unit && unit.length && item.unit_id) {
            const currentUnit = unit.filter(
                (d) => d.product_unit_id === item.unit_id
            )[0]; 
            let price =
                sale_type === "wholesale"
                    ? currentUnit.wholesale_price
                    : currentUnit.retail_price;
            const payload = { price };
            if (item.quantity) {
                payload.amount = price * item.quantity;
            }
            changeValue(payload);
        }
    }, [item.unit_id]);

    return (
        <tr className="" style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            <td>{rowId + 1}</td>
            <td style={{ maxWidth: "177px" }}>
                <span>{item.product.product_name}</span>
            </td>
            <td style={{ maxWidth: "108px" }}>
                <span>{item.saleUnit.unit_name}</span>
            </td>
            <td style={{ maxWidth: "108px" }}>
                <span>{item.sale_quantity}</span>
            </td>
            <td style={{ maxWidth: "150px" }}>
                <span>{formatCurrency(item.price)}</span>
            </td>
            <td style={{ maxWidth: "108px" }}>
                <span>{formatCurrency(item.amount)}</span>
            </td>
            <td className="text-center" style={{ maxWidth: "108px" }}>
                <input
                    type="checkbox"
                    // disabled
                    onClick={e => e.preventDefault()}
                    name="foc_status"
                    defaultChecked={item.foc_status}
                    value={item.foc_status}
                    onChange={onCheckBoxChange}
                    className="x-checkbox"
                />
            </td>
            <td className="button-col">
                <img
                    src={dotsIcon}
                    // onClick={() => toggleOptions(rowId)}
                />
                {/* <div className='popup-options' data-id={rowId}>
          <a href='' onClick={removeItem(rowId)}><img src={trashCanIcon}/>{ formatMessage('common.remove') }</a>
        </div> */}
            </td>
        </tr>
    );
};

export default SaleItemDetailsTable;
