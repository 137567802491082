import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useFetchData } from '../hooks/useFetchData'
import { BACKEND_URL } from '../utils/constant'

function BaseUnitDropdown({quantity, ...rest}) {

  const [units, setunits] = useState([])

  useEffect(() => {
    const url = BACKEND_URL + '/unit?unit_type=base&status=ACTIVE'
    axios.get(url, { withCredentials: true })
    .then(response => {
        console.log(response.data.data)
        setunits(response.data.data)
    })
    .catch(e => {
        console.log(e)
    })
  }, [])
  

  return (
    <div className='dd-container'>
        <select 
        type='text' 
        value=''
        className='x-input dropdown'
        {...rest}
        >
            <option value=''>Select</option>
            {
                units.map((item, index) => 
                (
                <option key={index} value={item.unit_id}>
                    {quantity ? quantity : ''} {item.unit_name}
                </option>
                ))
            }
        </select>

    </div>
  )
}

export default BaseUnitDropdown