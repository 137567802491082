import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";

function EditUnit() {
    const { handleInputChange, formData, setFormData, error, submitForm } =
        useForm();
    const history = useHistory();
    const params = useParams();
    const { setLoading } = useAuth();
    const formatMessage = useFormatMessage();
    const [xData, setxData] = useState({});

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        submitForm("PUT", "/unit/" + params.id, data, () => {
            history.push("/dashboard/unitManagement");
        });
    };

    useEffect(() => {
        const url = BACKEND_URL + "/unit?status=ACTIVE&unit_id=" + params.id;
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                const temp = response.data.data[0];
                setxData(temp);
                setFormData({
                    unit_name: temp.unit_name,
                    unit_type: temp.unit_type,
                    description: temp.description,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, []);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/unitManagement">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.unit")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.unitName")}
                                </label>
                                <input
                                    type="text"
                                    required
                                    name="unit_name"
                                    onChange={handleInputChange}
                                    value={
                                        formData.unit_name
                                            ? formData.unit_name
                                            : ""
                                    }
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.unitType")}
                                </label>
                                <div className="dd-container">
                                    <select
                                        required
                                        value={
                                            formData.unit_type
                                                ? formData.unit_type
                                                : ""
                                        }
                                        name="unit_type"
                                        className="x-input dropdown"
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="base">Base Unit</option>
                                        <option value="converted">
                                            Converted Unit
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.description")}
                                </label>
                                <input
                                    type="text"
                                    name="description"
                                    onChange={handleInputChange}
                                    value={
                                        formData.description
                                            ? formData.description
                                            : ""
                                    }
                                    className="x-input"
                                />
                            </div>
                        </div>
                        <div>
                            {error ? (
                                <span className="w-full block mb-3 text-lg text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}

                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/unitManagement`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.finish")}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="side-card-container">
                        <div className="side-card">
                            <div className="side-card-list">
                                <span className="label">Created by</span>
                                <span className="text">
                                    {xData.created_by_user
                                        ? xData.created_by_user.name
                                        : "-"}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Created on</span>
                                <span className="text">
                                    {moment(xData.created_date).format(
                                        "DD.MM.YYYY"
                                    )}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated by</span>
                                <span className="text">
                                    {xData.updated_by_user
                                        ? xData.updated_by_user.name
                                        : "-"}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated on</span>
                                <span className="text">
                                    {xData.updated_date
                                        ? moment(xData.updated_date).format(
                                              "DD.MM.YYYY"
                                          )
                                        : "-"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditUnit;
