import React from "react";
import homeImg from "../img/icon-home.png";
import saleImg from "../img/icon-sale.png";
import customerImg from "../img/icon-customer.png";
import deliveryImg from "../img/icon-transport.png";
import productImg from "../img/icon-product.png";
import unitImg from "../img/icon-unit.png";
import userImg from "../img/icon-user.png";
import reportImg from "../img/icon-report.png";
import downArrow from "../img/icon-downarrow.png";
import { Link, useHistory } from "react-router-dom";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { allow } from "../utils/common";
import { useAuth } from "../context/AuthProvider";

function Navigation() {
  const formatMessage = useFormatMessage();
  const saleSubNavItem = [
    {
      text: formatMessage("nav.subSale"),
      link: "/dashboard/saleManagement",
      submenu: "အရောင်းအဝယ် (wholesale)",
      action: "read",
    },
    {
      text: formatMessage("nav.saleAccepted"),
      link: "/dashboard/saleVoucher",
      submenu: "အရောင်းလက်ခံ (wholesale)",
      action: "read",
    },
    {
      text: formatMessage("nav.saleTransaction"),
      link: "/dashboard/saleTransaction",
      submenu: "အရောင်းအဝယ် (wholesale)",
      action: "read",
    },
    {
      text: formatMessage("nav.saleReturn"),
      link: "/dashboard/saleReturn",
      submenu: "အရောင်းပြန်ပေး (wholesale)",
      action: "read",
    },
  ];
  const productSubNavItem = [
    {
      text: formatMessage("nav.productCategory"),
      link: "/dashboard/productCategory",
      submenu: "ထုတ်ကုန်အမျိုးအစား",
      action: "read",
    },
    {
      text: formatMessage("nav.productManagement"),
      link: "/dashboard/productManagement",
      submenu: "ထုတ်ကုန်စီမံခန့်ခွဲမှု",
      action: "read",
    },
  ];
  const unitSubNavItem = [
    {
      text: formatMessage("nav.unitManagement"),
      link: "/dashboard/unitManagement",
      submenu: "ယူနစ်စီမံခန့်ခွဲမှု",
      action: "read",
    },
    {
      text: formatMessage("nav.unitConversion"),
      link: "/dashboard/unitConversion",
      submenu: "ယူနစ်ပြောင်းလဲခြင်း",
      action: "read",
    },
  ];

  return (
    <div className="navigation">
      <NavItem
        icon={homeImg}
        to="/dashboard/home"
        text={formatMessage("nav.home")}
        menu="home"
        action="read"
      />
      <NestedNavItem
        icon={saleImg}
        to="/dashboard/saleManagement"
        text={formatMessage("nav.sale")}
        subnav={saleSubNavItem}
      />
      <NavItem
        icon={customerImg}
        to="/dashboard/customer"
        text={formatMessage("nav.customer")}
        menu="ဝယ်ယူသူ"
        action="read"
      />
      <NavItem
        icon={deliveryImg}
        to="/dashboard/vehicle"
        text={formatMessage("nav.delivery")}
        menu="ပို့ဆောင်ရေး"
        action="read"
      />
      <NestedNavItem
        icon={productImg}
        to="/dashboard/productCategory"
        text={formatMessage("nav.product")}
        subnav={productSubNavItem}
      />
      <NestedNavItem
        icon={unitImg}
        to="/dashboard/unitManagement"
        text={formatMessage("nav.unit")}
        subnav={unitSubNavItem}
      />
      <NavItem
        icon={userImg}
        to="/dashboard/userManagement"
        text={formatMessage("nav.user")}
        menu="အသုံးပြုသူ"
        action="read"
      />
      <NavItem
        icon={reportImg}
        to="/dashboard/report"
        text={formatMessage("nav.report")}
        menu="စာရင်းချုပ်"
        action="read"
      />
    </div>
  );
}

const NavItem = ({ icon, text, to, menu, action }) => {
  const history = useHistory();
  const { user } = useAuth();
  const currentPath = history.location.pathname;
  const active = currentPath.startsWith(to);

  if (menu !== "home" && !allow(user.userAccess, menu, action)) {
    return <></>;
  }

  return (
    <Link to={to} className={`nav-item ${active ? "active" : ""}`}>
      <img src={icon} />
      <span>{text}</span>
    </Link>
  );
};

const NestedNavItem = ({ icon, to, text, subnav }) => {
  const { user } = useAuth();
  const history = useHistory();
  const currentPath = history.location.pathname;
  let isActive = false;
  subnav.forEach((item) => {
    if (currentPath.startsWith(item.link)) {
      isActive = true;
    }
  });

  subnav = subnav.filter((item) =>
    allow(user.userAccess, item.submenu, item.action)
  );
  console.log(subnav)
  if (subnav.length === 0) {
    return <></>;
  }

  return (
    <div className={`nested-nav-item`}>
      <Link to={to} className={`parent-nav ${isActive ? "active" : ""}`}>
        <img src={icon} />
        <span>{text}</span>
        <img src={downArrow} className="downarrow" />
      </Link>
      {isActive && (
        <div className="child-nav">
          {subnav.map((item, index) => {
            return (
              <Link
                key={index}
                className={`sub-nav-item ${
                  currentPath.startsWith(item.link) ? "active" : ""
                }`}
                to={item.link}
              >
                {item.text}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Navigation;
