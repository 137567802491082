import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './AuthProvider'

const PublicRoute = ({component:Component, authorized, ...rest}) => {
    const {user} = useAuth();
    return (
        <Route {...rest} render={(props)=> !user ? <Component {...props}/> : <Redirect to='/dashboard/home'/>}>

        </Route>
    )
}

export default PublicRoute