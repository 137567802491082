import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthProvider'
import { useFetchData } from '../hooks/useFetchData'
import { BACKEND_URL } from '../utils/constant'

function RoleDropdown({...rest}) {
  const {user} = useAuth()
  const [userRoles, setuserRoles] = useState([])

  useEffect(() => {
    const url = BACKEND_URL + '/userRole?status=ACTIVE'
    axios.get(url, { withCredentials: true })
    .then(response => {
        let temp = response.data.data;
        // if (user.role.user_role_name !== 'Super Admin')
        // {
        //   temp = temp.filter(item => item.user_role_name !== 'Admin' && item.user_role_name !== 'Super Admin')
        // }
        setuserRoles(temp)
    })
    .catch(e => {
        console.log(e)
    })
  }, [])
  

  return (
    <div className='dd-container'>
        <select 
        type='text' 
        value=''
        className='x-input dropdown'
        {...rest}
        >
            <option value=''>Select</option>
            {
                userRoles.map((item, index) => 
                (
                <option key={index} value={item.user_role_id}>
                    {item.user_role_name}
                </option>
                ))
            }
        </select>

    </div>
  )
}

export default RoleDropdown