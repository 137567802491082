import moment from "moment";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PriceItemContainer from "../../components/PriceItemContainer";
import ProductCategoryDropdown from "../../components/ProductCategoryDropdown";
import SaleReturnForm from "../../components/SaleReturnForm";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";

function CreateSaleReturn() {
    const { setLoading } = useAuth();
    const { fieldChange, formData, setFormData, submitForm, error, setError } =
        useForm();
    const [voucherNo, setvoucherNo] = useState("");
    const [data, setData] = useState(null);
    const formatMessage = useFormatMessage();
    const history = useHistory();

    const onSubmit = (e) => {
        e.preventDefault();
        const quantityChange = formData.items.reduce((value, item, index) => {
            return item.quantity && item.quantity > 0 ? true : value;
        }, false);

        if (!quantityChange) {
            setError("At least one quantity must return!");
            return;
        }

        const exceededItems = formData.items.map((item, index) => {
            let saleItem = data.saleInvoiceItems[index];
            if (
                item.quantity &&
                item.quantity > saleItem.quantity - saleItem.returnQuantity
            ) {
                item.exceeded = true;
            } else {
                item.exceeded = false;
            }
            return item;
        });

        if (exceededItems && exceededItems.filter((x) => x.exceeded).length) {
            const iarray = exceededItems.reduce((v, a, i) => {
                if (a.exceeded) {
                    v.push(i + 1);
                }
                return v;
            }, []);
            setError(
                "Return quantity exceeds the total quantity at row " +
                    iarray.join(",") +
                    "!"
            );
            return;
        }

        const payload = {
            ...formData,
            items: formData.items.filter(
                (item) => item.quantity && item.quantity > 0
            ),
            description: "",
            status: "ACTIVE",
        };
        setLoading(true);
        submitForm("POST", "/saleReturn/cascadeCreate", payload, () => {
            document
                .getElementById("external-popup")
                .classList.toggle("show", false);

            document.querySelector('input[name="voucherNo"]').value = "";
            setvoucherNo("");
            setError("");
        });
    };

    const onSearch = (e) => {
        e.preventDefault();
        const input = document.querySelector('input[name="voucherNo"]');
        setvoucherNo(input.value);
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/saleReturn">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createSaleReturn")}
                </h1>

                <label className="x-label mt-9">
                    {formatMessage("common.voucherNo")}
                </label>
                <div className="flex">
                    <div className="search-bar">
                        <input
                            name="voucherNo"
                            className="x-input"
                            style={{ paddingLeft: "32px" }}
                            type="search"
                        />
                    </div>
                    <button onClick={onSearch} className="create-button">
                        {formatMessage("common.search")}
                    </button>
                </div>

                <div
                    className="x-divider mt-11"
                    style={{ maxWidth: "1032px" }}
                ></div>

                <div
                    className="form-container mt-5"
                    style={{ minHeight: "80%", height: "auto" }}
                >
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div>
                            <SaleReturnForm
                                data={data}
                                setData={setData}
                                voucherNo={voucherNo}
                                fieldChange={fieldChange}
                                setFormData={setFormData}
                                formData={formData}
                            />
                        </div>

                        <div className="mt-28 pr-10">
                            {error ? (
                                <span className="w-full mb-3 block text-lg text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/saleReturn`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button
                                    onClick={onSubmit}
                                    className="black-btn"
                                >
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateSaleReturn;
