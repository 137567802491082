import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BACKEND_URL } from '../utils/constant'

function ProductUnitDropdown({unit, className = '', ...rest}) {

  return (
    <div className='dd-container'>
        <select 
        type='text' 
        value=''
        className={'x-input dropdown '+ className}
        {...rest}
        >
            <option value=''>Select</option>
            {
                unit.map((item, index) => 
                (
                <option key={index} value={item.product_unit_id}>
                    {item.unit.unit_name}
                </option>
                ))
            }
        </select>

    </div>
  )
}

export default ProductUnitDropdown