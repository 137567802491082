import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import RoleDropdown from '../../components/RoleDropdown';
import { useForm } from '../../hooks/useForm'
import { useFormatMessage } from '../../hooks/useFormatMessage';
import backArrowIcon from '../../img/back-arrow.png'
import { BACKEND_URL } from '../../utils/constant';
import { useAuth } from '../../context/AuthProvider';

function EditUser() {
  const {handleInputChange, formData, setFormData, submitForm, error} = useForm();
  const history = useHistory();
  const params = useParams();
  const {setLoading} = useAuth();
  const formatMessage = useFormatMessage();
  const [xData, setxData] = useState({})

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const data = {
        ...formData,
        status: 'ACTIVE'
    }
    submitForm('PUT', '/user/'+params.id, data, () => {
        history.push('/dashboard/userManagement')
    });
  }

  useEffect(() => {
    const url = BACKEND_URL + '/user?status=ACTIVE&user_id='+ params.id;
    axios.get(url, { withCredentials: true })
    .then(response => {
        const temp = response.data.data[0];
        setxData(temp)
        setFormData({
            user_role_id: temp.user_role_id,
            name: temp.name,
            username: temp.username,
            phone: temp.phone,
            description: temp.description
        })
    })
    .catch(e => {
        console.log(e.response)
    })
  
  }, [])
  

  return (
    <div className='sub-page-layout-1'>
        <Link className='back-to-page' to='/dashboard/userManagement'>
            <img src={backArrowIcon} />
            { formatMessage('common.back') }
        </Link>
        <div className='container'>
            <h1 className='sub-page-title'>{ formatMessage('common.user') }</h1>
            <div className='form-container'>
                <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                    <div className='grid grid-cols-2 gap-9' style={{maxWidth: '995px'}}>

                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.userRole') }</label>
                            <RoleDropdown 
                            value={formData.user_role_id ? formData.user_role_id : ''}
                            onChange={handleInputChange} 
                            required
                            name='user_role_id' 
                            />

                        </div>
                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.name') }</label>
                            <input type='text' 
                            required
                            name='name' 
                            value={formData.name ? formData.name : ''}
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.username') }</label>
                            <input type='text' 
                            required
                            disabled
                            value={formData.username ? formData.username : ''}
                            name='username' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.phone') }</label>
                            <input type='text' 
                            required
                            value={formData.phone ? formData.phone : ''}
                            name='phone' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label'>{ formatMessage('common.description') }</label>
                            <input type='text' 
                            value={formData.description ? formData.description : ''}
                            name='description' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>

                    </div>
                    <div>
                        { error ? <span className='w-full block mb-5 text-lg text-red-500 text-right'>{error}</span> : ''}
                        <div className='flex justify-end gap-5'>
                            <Link to={`/dashboard/userManagement`} className='white-btn'>{ formatMessage('common.cancel') }</Link>
                            <button className='black-btn'>{ formatMessage('common.finish') }</button>
                        </div>
                     </div>
                </form>

                <div className='side-card-container'>
                    <div className='side-card'>
                        <div className='side-card-list'>
                            <span className='label'>Created by</span>
                            <span className='text'>{ xData.created_by_user ? xData.created_by_user.name : '' }</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Created on</span>
                            <span className='text'>{ xData ? moment(xData.created_date).format('DD.MM.YYYY') : '' }</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated by</span>
                            <span className='text'>{ xData.updated_by ? xData.updated_by_user.name : '-' }</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated on</span>
                            <span className='text'>{ xData.updated_date ? moment(xData.updated_date).format('DD.MM.YYYY') : '-'}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    
    </div>

  )
}

export default EditUser