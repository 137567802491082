import axios from "axios";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { BACKEND_URL } from "../utils/constant";
import searchIcon from "../img/icon-search.png";
import Popup from "./Popup";
import { useFormatMessage } from "../hooks/useFormatMessage";

function ProductDropdown({ sale_items, rowId, setItems, className, ...rest }) {
    const [products, setProducts] = useState({
        origin: [],
        render: [],
    });
    const [popup, setPopup] = useState(false);
    const [searchSelected, setSearchSelected] = useState();
    const [input, setInput] = useState('');
    const formatMessage = useFormatMessage();

    useEffect(() => {
        const url = BACKEND_URL + "/product?status=ACTIVE";
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                let results = response.data.data;

                let tempProducts = results.filter((item) => {
                    let temp = sale_items.filter(
                        (d, i) =>
                            d.product_id == item.product_id &&
                            d.foc_status == false
                    );
                    let isExist = temp.length > 0;
                    return !isExist;
                });
                setProducts({
                    origin: results,
                    render: tempProducts,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        console.log(sale_items);
        let tempProducts = products.origin;
        tempProducts = tempProducts.filter((item) => {
            let included = true;
            let plength = sale_items.filter(
                (d, i) => i != rowId && d.product_id == item.product_id
            ).length;
            if (plength > 1) {
                return false;
            }

            sale_items.forEach((d, i) => {
                if (
                    i != rowId &&
                    d.product_id == item.product_id &&
                    d.foc_status == false &&
                    !(
                        (sale_items[rowId].foc_status != true &&
                            d.foc_status == true) ||
                        sale_items[rowId].foc_status == true
                    )
                ) {
                    included = false;
                }
            });
            return included;
        });
        setProducts({
            ...products,
            render: tempProducts,
        });
    }, [sale_items]);

    useEffect(() => {
        if (!popup) {
            document.getElementById("external-popup").classList.remove("show");
        }
    }, [popup]);

    const onSearchConfirm = (e) => {
        e.preventDefault();

        if (!searchSelected)
        {
            return;
        }

        const temp = [...sale_items];
        temp[rowId] = {
            ...temp[rowId],
            product_id: searchSelected,
        };
        setItems(temp);

        setPopup(false)
    };


    const onSearchInputChange = e => {
        const {name, value} = e.target;
        setSearchSelected(null)
        setInput(value)
    }

    const onSearchItemSelect = item => e => {
        setSearchSelected(item.product_id);
        setInput(item.product_name)
        document.getElementById('psd-1').style.display = 'none'
    }

    const showPopup = e => {
        setInput('')
        setPopup(true)

    }

    useEffect(() => {
        if (!popup)
        {
            return;
        }

        let sdd = document.getElementById('psd-1');
        if (input && !searchSelected) {
            sdd.style.display = 'block'
        }
        else
        {
            sdd.style.display = 'none'
        }

    }, [input])

    return (
        <div className="flex gap-2 items-center">
            <div className="dd-container flex-grow">
                <select
                    type="text"
                    value=""
                    className={"x-input dropdown " + className}
                    {...rest}
                >
                    <option value="">Select</option>
                    {products.render.map((item, index) => (
                        <option key={index} value={item.product_id}>
                            {item.product_name}
                        </option>
                    ))}
                </select>
            </div>
            <img
                className="cursor-pointer"
                onClick={showPopup}
                style={{ width: "26px", height: "auto" }}
                src={searchIcon}
            />

            {popup && (
                <Popup className="popup-container show">
                    <div className="popup w-full">
                        <label>{formatMessage("common.product")}</label>
                        <div className="relative">
                            <input value={input} name="search-input" onChange={onSearchInputChange} className="x-input mt-3" />

                            <div id="psd-1" className="absolute w-full border rounded-sm max-h-96 bg-white overflow-y-auto" style={{display: 'none'}}>
                                {products.render.filter(item => {
                                    let temp = input || '';
                                    temp = temp.trim().toLowerCase()
                                    const regex = new RegExp(`\\b${temp}\\b|^${temp}|${temp}|${temp}$`);
                                    let found =  regex.test(item.product_name.toLowerCase())
                                    console.log(found)
                                    return found != false
                                }).map((item, index) => (
                                    <div key={index} onClick={ onSearchItemSelect(item) } className="cursor-pointer py-2 px-6 hover:bg-slate-100">
                                        {item.product_name}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex gap-4 justify-center mt-11">
                            <button
                                onClick={() => setPopup(false)}
                                className="white-btn"
                            >
                                {formatMessage("common.cancel")}
                            </button>
                            <button
                                onClick={onSearchConfirm}
                                className="black-btn"
                            >
                                {"Confirm"}
                            </button>
                        </div>
                    </div>
                </Popup>
            )}
        </div>
    );
}

export default ProductDropdown;
