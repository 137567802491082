import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency } from "../../utils/common";

function ReceivableReportByDate() {
    const history = useHistory();
    const params = useParams();
    const formatMessage = useFormatMessage();
    const [data, setdata] = useState({
        start_date: "",
        end_date: "",
        // page: 1,
        // limit: 10,
        results: null,
    });

    const onSearch = (e) => {
        e.preventDefault();
        let query = [];
        query.push(`start_date=${data.start_date}`);
        query.push(`end_date=${data.end_date}`);

        query = query.join("&");
        let url = BACKEND_URL + "/report/receivableByDate";
        axios
            .get(`${url}?${query}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log('Response - ', response.data)
                setdata({
                    ...data,
                    results: response.data,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    const onChange = (e) => {
        const  {name, value} = e.target;
        setdata({
            ...data,
            [name]: value,
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.receivableReportByDate")}</h1>
                <form onSubmit={onSearch} className="flex">
                    <input
                        required
                        name="start_date"
                        onChange={onChange}
                        className="x-input mr-3"
                        type="date"
                    />
                    <input
                        required
                        name="end_date"
                        onChange={onChange}
                        className="x-input mr-4"
                        type="date"
                    />
                    <button type="submit" className="header-img-button mr-1">
                        <img src={searchIcon} />
                    </button>
                    <button className="header-img-button">
                        <img src={printIcon} />
                    </button>
                </form>
            </div>

            {data.results && data.results.data && 
            <div className="table-container">
                <table className="x-table">
                    <thead>
                        <tr>
                            <th>{formatMessage("common.no")}</th>
                            <th>{formatMessage("common.voucherNo")}</th>
                            <th>{formatMessage("common.invoiceDate")}</th>
                            <th>{formatMessage("common.acceptedDate")}</th>
                            <th className="text-end">{formatMessage("common.salesAmount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.results.data.map((item, i) => {
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{i + 1}</td>
                                    <td>{item.sale_invoice}</td>
                                    <td>{item.invoice_date}</td>
                                    <td>{item.due_date}</td>
                                    <td className="text-end">{formatCurrency(item.amount)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="flex mt-5 justify-end gap-8 pr-12">
                    <span>{ formatMessage('common.total') } </span> 
                    -
                    <span>{ formatCurrency(data.results.total)}</span> 
                </div>

            </div>}
        </div>
    );
}

export default ReceivableReportByDate;
