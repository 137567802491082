import moment from "moment";
import { voucherCSS } from "./constant";

export const allow = (data, menu, action) => {
    const xtemp = data.filter((item) => item.subMenu.submenu_name === menu)[0];
    if (xtemp && xtemp[action] === 1) {
        return true;
    } else {
        return false;
    }
};

export const formatCurrency = (value, removePoint = true) => {
    if (!value) {
        return 0;
    }

    value = value.toString();
    var parts = value.split(".");
    var wholeNumber = parts[0];
    var decimal = parts.length > 1 && !removePoint ? "." + parts[1] : "";

    var regex = /(\d)(?=(\d{3})+$)/g;
    wholeNumber = wholeNumber.replace(regex, "$1,");

    return wholeNumber + decimal;
};

export const getSelectableYears = () => {
    let now = moment().format("YYYY") * 1;
    let years = [];
    for (let i = 2023; i <= now + 100; i++) {
        years.push(i);
    }
    return years;
};

export const getFullMonth = (m) => {
    return moment(m, "M").format("MMMM");
};

export const prepareVoucherWindow = (a, isLast) => {
    if (isLast) {
        document.querySelector("#voucher .total-calc").style.opacity = "1";
    }
    else {
        document.querySelector("#voucher .total-calc").style.opacity = "0";
    }
    const divContents = document.querySelector("#voucher").innerHTML;
    a.document.write("<html><body>");
    a.document.write(`<style>${voucherCSS}</style>`);

    a.document.write(divContents);
    a.document.write("</body></html>");
};

export const populateItemIntoVoucher = (items, start, pageNumber, limit = 11) => {
    let template = "";

    for (let i = 0; i < limit; i++) {
        if (i < items.length) {
            template += `
            <tr >
                    <td>${start++}</td>
                    <td>
                        ${items[i].product.product_name}
                    </td>
                    <td>${items[i].sale_quantity}</td>
                    <td>
                        ${formatCurrency(items[i].price)}
                    </td>
                    <td>
                        ${
                            items[i].foc_status == true
                                ? "0"
                                : formatCurrency(
                                      items[i].sale_quantity * items[i].price
                                  )
                        }
                    </td>
                </tr>
            `;
        } else {
            template += `
            <tr key={item}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>`;
        }
    }

    let voucherBody = document.getElementById("voucher-table-body");
    voucherBody.innerHTML = template;
    document.getElementById('voucher-page').innerText = pageNumber
};
