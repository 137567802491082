import  axios  from 'axios'
import { useState, useEffect } from 'react'
import { BACKEND_URL } from '../utils/constant'

export const useCheckUser = () => {
    const [user, setUser] = useState('')
    const [isLoading, setisLoading] = useState(true)
    
    const checkUser = (callback = ()=> {}) => {
        const authUrl = BACKEND_URL + '/auth/me'
        axios.get(authUrl, {withCredentials: true})
        .then(response => {
            setUser({
                ...response.data.data,
                userAccess: response.data.userAccess
            })
            setisLoading(false)
            callback();
        })
        .catch(error => {
            
            setUser(null)
            setisLoading(false)
        });
        
    }

    useEffect(() => {
        checkUser(() => {});
    }, [])

    return {
        user,
        setUser,
        isLoading,
        checkUser
    }

}
