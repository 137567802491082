import moment from "moment";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import "../../css/common.css";
import UnitDropdown from "../../components/UnitsPerSKUDropdown";
import SKUUnitDropdown from "../../components/SKUUnitDropdown";
import BaseUnitDropdown from "../../components/BaseUnitDropdown";
import ConvertedUnitDropdown from "../../components/ConvertedUnitDropdown";

function CreateUnitConversion() {
    const { handleInputChange, formData, submitForm, setError, error } =
        useForm({
            converted_qty: 1,
        });
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        if (formData.base_unit_id !== formData.converted_unit_id) {
            const data = {
                ...formData,
                status: "ACTIVE",
                active: 1,
            };
            setLoading(true);
            submitForm("POST", "/uoc", data, () => {
                history.push("/dashboard/unitConversion");
            });
        } else {
            setError("Base unit and converted unit must not be same!");
        }
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/unitConversion">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createUoc")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.baseUnit")}
                                </label>
                                <BaseUnitDropdown
                                    onChange={handleInputChange}
                                    value={formData.base_unit_id}
                                    required
                                    name="base_unit_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.baseQty")}
                                </label>
                                <input
                                    type="number"
                                    required
                                    name="base_qty"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>

                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.convertedUnit")}
                                </label>
                                <ConvertedUnitDropdown
                                    name="converted_unit_id"
                                    value={formData.converted_unit_id}
                                    required
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.convertedQty")}
                                </label>
                                <input
                                    type="number"
                                    required
                                    disabled
                                    name="converted_qty"
                                    onChange={handleInputChange}
                                    value={formData.converted_qty}
                                    className="x-input"
                                />
                            </div>

                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.description")}
                                </label>
                                <input
                                    type="text"
                                    name="description"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>
                        <div>
                            {error ? (
                                <span className="w-full block text-lg text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5 mt-5">
                                <Link
                                    to={`/dashboard/unitConversion`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="side-card-container">
                        <div className="side-card">
                            <div className="side-card-list">
                                <span className="label">Created by</span>
                                <span className="text">{user.name}</span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Created on</span>
                                <span className="text">
                                    {moment().format("DD.MM.YYYY")}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated by</span>
                                <span className="text">-</span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated on</span>
                                <span className="text">-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateUnitConversion;
