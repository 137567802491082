import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useFetchData } from '../hooks/useFetchData'
import { BACKEND_URL } from '../utils/constant'

function UnitsPerSKUDropdown({sku_unit_id, quantity, ...rest}) {

  const [units, setunits] = useState([])

  useEffect(() => {
    if (!sku_unit_id)
    {
      return
    }
    const url = BACKEND_URL + '/uoc?included=true&status=ACTIVE&base_unit_id=' + sku_unit_id
    axios.get(url, { withCredentials: true })
    .then(response => {
        console.log(response.data.data)
        let tempUnits = []
        if (response.data.data.length > 0)
        {
          tempUnits.push(response.data.data[0].baseUnit)
          tempUnits = tempUnits.concat(response.data.data.map(item => item.convertedUnit))

          setunits(tempUnits)
        }
        else {
          axios.get(`${BACKEND_URL}/unit?included=true&status=ACTIVE&unit_id=${sku_unit_id}`, { withCredentials: true })
          .then(response => {
            tempUnits.push(response.data.data[0])
            setunits(tempUnits)
          })
        }
    })
    .catch(e => {
        console.log(e)
    })
  }, [sku_unit_id])
  

  return (
    <div className='dd-container'>
        <select 
        type='text' 
        value=''
        className='x-input dropdown'
        {...rest}
        >
            <option value=''>Select</option>
            {
                units.map((item, index) => 
                (
                <option key={index} value={item.unit_id}>
                    {quantity ? quantity : ''} {item.unit_name}
                </option>
                ))
            }
        </select>

    </div>
  )
}

export default UnitsPerSKUDropdown