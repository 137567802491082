import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useFormatMessage } from '../hooks/useFormatMessage';
import { formatCurrency } from '../utils/common';
import { BACKEND_URL } from '../utils/constant';

function SaleReturnForm({voucherNo, fieldChange, data, setData, setFormData, formData}) {
  const formatMessage = useFormatMessage();
    
  useEffect(() => {
    if (voucherNo)
    {
        const url = BACKEND_URL + '/saleInvoice?status=ACTIVE&included=true&sale_invoice='+ voucherNo;
        axios.get(url, { withCredentials: true })
        .then(response => {
            console.log(response.data.data)
            if (response.data.data.length > 0)
            {
                const temp = response.data.data[0];
                setData(temp)
                const items = temp.saleInvoiceItems.map(d => ({
                  product_id: d.product_id,
                  unit_id: d.unit_id,
                  quantity: '',
                  price: d.price
                }))
                setFormData({
                  sale_invoice_id: temp.sale_invoice_id,
                  sale_return_amount: 0,
                  items
                })
            }
            else 
            {
              setData(null)
              setFormData({items: []})
            }
        })
        .catch(e => {
            console.log(e.response)
        })
    }
    else {
      setData(null)
      setFormData({items: []})
    }
  
  }, [voucherNo])
  

  const itemInputChange = index => e => {
    const temp = [...formData.items];
    const {name, value} = e.target;
    temp[index] = {
      ...temp[index],
      [name]: value
    }
    fieldChange('items')(temp);
  }

  useEffect(() => {
    if (formData.items)
    {
      const amount = formData.items.reduce((value,item, index) => {
        return item.quantity ? value + (item.quantity * item.price) : value;
      }, 0)
      setFormData({
        ...formData,
        sale_return_amount: amount
      })

    }
  }, [formData.items])
  

  return (
    data && formData && 
    <div>
      <h3>{ formatMessage('common.voucherDetails') }</h3>

      <div className='table-container'>
        <table className='x-table'>
          <thead>
            <tr>
              <th>{ formatMessage('common.no') }</th>
              <th>{ formatMessage('common.product') }</th>
              <th>{ formatMessage('common.unit') }</th>
              <th>{ formatMessage('common.quantity') }</th>
              <th>{ formatMessage('common.price') }</th>
              <th>{ formatMessage('common.amount') }</th>
              <th>{ formatMessage('common.returnQuantity') }</th>
              <th>{ formatMessage('common.returnAmount') }</th>
            </tr>

          </thead>
          <tbody>
            {
              data.saleInvoiceItems.filter(d => d.foc_status == false).map((item, i) => {
                return (
                <tr key={i+1} className=''>
                  <td>{i+1}</td>
                  <td>{item.product.product_name}</td>
                  <td>{item.saleUnit.unit_name}</td>
                  <td>{item.sale_quantity - item.returnQuantity}</td>
                  <td>{formatCurrency(item.price)}</td>
                  <td>{formatCurrency((item.sale_quantity - item.returnQuantity) * item.price)}</td>
                  <td>
                    <input 
                    type='number'
                    required
                    name='quantity'
                    value={formData.items[i].quantity}
                    onChange={itemInputChange(i)}
                    className='x-input' 
                    />
                </td>
                  <td>{formatCurrency(formData.items[i].price * formData.items[i].quantity) }</td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      
      <div className='flex justify-end gap-11 mt-11' style={{marginRight: '4.3rem'}}>
          <span>Total Return</span>
          <span>-</span>
          <span className='pl-6 inline-block w-32'>{ formatCurrency(formData.sale_return_amount) }</span>
      </div>

    </div>
  )
}

export default SaleReturnForm