import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import { Link, useHistory } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";

function Product() {
    const formatMessage = useFormatMessage();
    let reportArray = [
        {
            group: 'wholesale',
            title: formatMessage("common.wholesaleReportByDate"),
            link: "/dashboard/report/wholesaleReportByDate",
        },
        {
            group: 'wholesale',
            title: formatMessage("common.wholesaleReportByYearly"),
            link: "/dashboard/report/wholesaleReportByYearly",
        },
        {
            group: 'wholesale',
            title: formatMessage("common.wholesaleReportByYearlyAndProduct"),
            link: "/dashboard/report/wholesaleReportByYearlyAndProduct",
        },
        {
            group: 'wholesale',
            title: formatMessage("common.wholesaleReportByCNDate"),
            link: "/dashboard/report/wholesaleReportByCNDate",
        },
        {
            group: 'wholesale',
            title: formatMessage("common.wholesaleReportByCNYear"),
            link: "/dashboard/report/wholesaleReportByCNYear",
        },
        {
            group: 'receivable',
            title: formatMessage("common.receivableReportByDate"),
            link: "/dashboard/report/receivableReportByDate",
        },
        {
            group: 'receivable',
            title: formatMessage("common.receivableReportByYear"),
            link: "/dashboard/report/receivableReportByYear",
        },
        {
            group: 'receivable',
            title: formatMessage("common.receivableReportByCustomer"),
            link: "/dashboard/report/receivableReportByCustomer",
        },
        {
            group: 'receivable',
            title: formatMessage("common.receivableReportByCity"),
            link: "/dashboard/report/receivableReportByCity",
        },
        {
            group: 'vehicle',
            title: formatMessage("common.vehicleDetailReport"),
            link: "/dashboard/report/vehicleDetailReport",
        },
        {
            group: 'vehicle',
            title: formatMessage("common.distributionReportByDate"),
            link: "/dashboard/report/distributionReportByDate",
        },
        {
            group: 'vehicle',
            title: formatMessage("common.distributionReportByYear"),
            link: "/dashboard/report/distributionReportByYear",
        },
    ];

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>{formatMessage("common.report")}</h1>
            </div>

            <h4 className="mt-8 text-lg">Wholesale Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'wholesale').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>
            <h4 className="mt-8 text-lg">Receivable Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'receivable').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>
            <h4 className="mt-8 text-lg">Vehicle Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'vehicle').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>
        </div>
    );
}

const ReportItem = ({ item }) => {
    return (
        <li className="mb-5">
            <Link className="text-xl underline" to={item.link}>{item.title}</Link>
        </li>
    );
};

export default Product;
