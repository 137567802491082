import moment from 'moment';
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider';
import { useForm } from '../../hooks/useForm'
import { useFormatMessage } from '../../hooks/useFormatMessage';
import backArrowIcon from '../../img/back-arrow.png'

function CreateProductCategory() {
  const {setLoading} = useAuth();
  const {handleInputChange, formData, submitForm, error} = useForm();
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const {user} = useAuth();
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const data = {
        ...formData,
        status: 'ACTIVE'
    }
    submitForm('POST', '/productCategory', data, () => {
        history.push('/dashboard/productCategory')
    });
  }

  return (
    <div className='sub-page-layout-1'>
        <Link className='back-to-page' to='/dashboard/productCategory'>
            <img src={backArrowIcon} />
            { formatMessage('common.back') }
        </Link>
        <div className='container'>
            <h1 className='sub-page-title'>{ formatMessage('common.createProductCategory') }</h1>
            <div className='form-container'>
                <form onSubmit={onSubmit} className='form flex flex-col justify-between w-full'>
                    <div className='flex gap-9'>

                        <div className='grow'>
                            <label className='x-label input-required'>{ formatMessage('common.productCategoryName') }</label>
                            <input 
                            type='text' 
                            required
                            name='product_category_name' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                        <div className='grow'>
                            <label className='x-label'>{ formatMessage('common.description') }</label>
                            <input type='text' 
                            name='description' 
                            onChange={handleInputChange}
                            className='x-input'/>

                        </div>
                    </div>
                    <div className=''>
                        { error ? <span className='w-full block text-lg mb-3 text-red-500 text-right'>{error}</span> : ''}
                        <div className='flex justify-end gap-5'>
                            <Link to={`/dashboard/productCategory`} className='white-btn'>{ formatMessage('common.cancel') }</Link>
                            <button className='black-btn'>{ formatMessage('common.create') }</button>
                        </div>
                    </div>
                </form>

                <div className='side-card-container'>
                    <div className='side-card'>
                        <div className='side-card-list'>
                            <span className='label'>Created by</span>
                            <span className='text'>{user.name}</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Created on</span>
                            <span className='text'>{moment().format('DD.MM.YYYY')}</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated by</span>
                            <span className='text'>-</span>
                        </div>
                        <div className='side-card-list'>
                            <span className='label'>Updated on</span>
                            <span className='text'>-</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    
    </div>

  )
}

export default CreateProductCategory