import moment from "moment";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";

function CreateUnit() {
    const { handleInputChange, formData, submitForm, error } = useForm();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();
    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
            active: 1,
        };
        submitForm("POST", "/unit", data, () => {
            history.push("/dashboard/unitManagement");
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/unitManagement">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createUnit")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.unitName")}
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="unit_name"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.unitType")}
                                </label>
                                <div className="dd-container">
                                    <select
                                        required
                                        value={formData.unit_type}
                                        name="unit_type"
                                        className="x-input dropdown"
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="base">Base Unit</option>
                                        <option value="converted">Converted Unit</option>
                                
                                    </select>
                                </div>
                            </div>
                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.description")}
                                </label>
                                <input
                                    type="text"
                                    name="description"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/unitManagement`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="side-card-container">
                        <div className="side-card">
                            <div className="side-card-list">
                                <span className="label">Created by</span>
                                <span className="text">{user.name}</span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Created on</span>
                                <span className="text">
                                    {moment().format("DD.MM.YYYY")}
                                </span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated by</span>
                                <span className="text">-</span>
                            </div>
                            <div className="side-card-list">
                                <span className="label">Updated on</span>
                                <span className="text">-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateUnit;
