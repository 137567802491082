import axios from "axios";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../context/AuthProvider";
import { BACKEND_URL } from "../utils/constant";
import PasswordInput from "./PasswordInput";

const { useState } = require("react");
const { useForm } = require("../hooks/useForm");
const { useFormatMessage } = require("../hooks/useFormatMessage");


export default function LanguagePopup({setPopup, popup}) {
    const formatMessage = useFormatMessage();
  	const {switchLang, lang, setUser, checkUser} = useAuth() ;

    const onChangeLang = (e) => {
      console.log(e.target.id)
      switchLang(e.target.id)
    }

    const onSubmit = e => {
      e.preventDefault();

  
    }
  
    const onCloseClick = e => {
      e.preventDefault();
      setPopup({
        id: '',
        show: false,
        eID: ''
      })
    }
  
    return (
      <form onSubmit={onSubmit} className='popup w-full' 
      style={{
        maxWidth: '546px ',
        padding: '40px 50px 48px 50px '  
      }}>
        <h4 className='text-center mb-8'>‌{ formatMessage('common.changeLang') }</h4>

        <div className='radio-item text-center'> 
          <input id='eng' name='lang' checked={lang === 'en'} type='radio' onChange={onChangeLang}/>
          <label htmlFor='eng'>
            <FormattedMessage id='login.eng'/>
          </label>
          <input id='myan' name='lang' checked={lang === 'my'} type='radio' onChange={onChangeLang} />
          <label htmlFor='myan'>
            <FormattedMessage id='login.myan'/>
          </label>
        </div>
        
        <div className=' mt-14'>
          <div className='flex justify-center gap-5'>
            <button onClick={onCloseClick} className='white-btn'>{ formatMessage('common.close') }</button>
          </div>
        </div>
      </form>
    )
}

