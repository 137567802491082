import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormatMessage } from '../../hooks/useFormatMessage';
import backArrowIcon from '../../img/back-arrow.png'
import { BACKEND_URL } from '../../utils/constant';
import dotsIcon from '../../img/icondots.png'
import { formatCurrency } from '../../utils/common';

function SaleReturnDetails() {
  const formatMessage = useFormatMessage();
  const [data, setData] = useState(null)
  const params = useParams();

  useEffect(() => {
    const url = BACKEND_URL + '/saleReturnItem?included=true&sale_return_id=' + params.id;
    axios.get(url, { withCredentials: true })
    .then(response => {
        console.log(response.data.data)
        setData(response.data.data)
    })
    .catch(e => {
        console.log(e.response)
    })
  }, [params.id])
  


  return (
    <div className='sub-page-layout-1'>
        <Link className='back-to-page' to='/dashboard/saleReturn'>
            <img src={backArrowIcon} />
            { formatMessage('common.back') }
        </Link>
        <div className='container'>
            <h1 className='sub-page-title'>{ formatMessage('common.productDetails') }</h1>

            <div className='table-container'>
                <table className='x-table'>
                <thead>
                    <tr>
                    <th>{ formatMessage('common.no') }</th>
                    <th>{ formatMessage('common.product') }</th>
                    <th>{ formatMessage('common.measurementUnit') }</th>
                    <th>{ formatMessage('common.quantity') }</th>
                    <th>{ formatMessage('common.amount') }</th>
                    <th className='button-col'></th>
                    </tr>

                </thead>
                <tbody>
                    {
                    data && data.map((item, i) => {
                        return (
                        <tr key={i} className=''>
                        <td>{i+1}</td>
                        <td>{ item.product.product_name }</td>
                        <td>{ item.saleUnit.unit_name }</td>
                        <td>{ item.quantity }</td>
                        <td>{ formatCurrency(item.return_quantity * item.price) }</td>
                        <td className='button-col'>
                            <img src={dotsIcon}/>
                            {/* <div className='popup-options' id={item.sale_return_id}>
                            <Link to={`${currentPath}/${item.sale_return_id}/view`}><img src={detailsIcon}/>{ formatMessage('common.details') }</Link>
                            <a href='' onClick={onDeleteClick}><img src={trashCanIcon}/>{ formatMessage('common.remove') }</a>
                            </div> */}
                        </td>
                        </tr>
                        )
                    })
                    }
                </tbody>
                </table>
            </div>

        </div>
    
    </div>

  )
}

export default SaleReturnDetails